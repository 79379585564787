@import "../../common.scss";

.dialog-header {
  width: 100%;
  padding: 12px !important;
  background: $blue3;
  height: 72px;
  align-items: center;
  display: flex;

  h2 {
    width: 100%;
  }
}
.dialog-header-width{
  max-width: 792px!important;
}

.dialog-content {
  background: $gray5 !important;
  padding: 16px !important;
  width: 100%;
  height: 100%;
}
