@import '../../common.scss';

.box {
  background-color: $white;
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;
}
.box-title {
  font-weight: $semiBoldFont;
  font-size: $xlargeFontSize;
  line-height: 150%;
  color: $black;

  h3 {
    margin-top: 0px;
  }
}

.box-time {
  font-size: $commonFontSize;
  color: $gray2;
}
.box-subheading {
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  margin-bottom: 22px;
  color: $gray2;
  display: flex;
}
.box-content {
  font-style: normal;
  font-weight: normal;
  font-size: $commonFontSize;
  line-height: 160%;
  color: $gray1;
  white-space: pre-line;
}
.box-img {
  margin-right: 12px;
}
.box-btn {
  width: 60%;
}

.btn-color {
  color: $white;
  font-weight: normal;
  max-width: 180px;
  width: 100%;
  font-size: $commonFontSize;
  height: 48px;
}
