@import '../../common.scss';
.section {
  width: 50%;
}
.wrapperSignUp {
  display: flex;
  justify-content: center;
}
.leftWrapper {
  max-width: 760px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}
.right {
  position: fixed;
  width: 50%;
}

.mobile {
  display: block;
}

@media screen and (max-width: 850px) {
  .mobile {
    display: none;
  }

  .section {
    width: 100%;
  }
}
