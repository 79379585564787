@import '../../common.scss';

.list-card {
  margin-bottom: 16px;
  border: 1px solid #f0f1f3;
  padding: 16px;
  display: flex;
  background: $white;
  min-height: 209px;
  flex-direction: column;
}

.pointer {
  cursor: pointer;
}

.review-card__time-icon {
  height: 12px;
  margin-right: 5px;
}

.review-card__align-items {
  display: flex;
  align-items: center;
  margin-right: 16px;
  height: 22px;
  white-space: nowrap;
  padding: 0 8px;
  font-size: $xsmalFontSize;
  line-height: 120%;
  color: $gray2;
  background: $white5;
  font-weight: $mediumFont;
}

.list-card-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.d-flex {
  display: flex;
}

.white {
  color: $white;
  background: $red1;
  font-size: $commonFontSize;
  font-weight: normal;
}

.green {
  background: $green1;
  color: $white;
  font-size: $commonFontSize;
  font-weight: normal;
}

.cancel-btn {
  background-color: $gray0;
  color: $black;
  font-size: $commonFontSize;
  margin-top: 10px;
  font-weight: normal;
}

.dotted-menu {
  cursor: pointer;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.list-card-title {
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
  line-height: 150%;
  color: $orange2;
  margin-top: 16px;

  h3 {
    margin: 0px;
  }
}

.list-card-content {
  font-size: $commonFontSize;
  line-height: 160%;
  color: $gray2;
  margin-bottom: 24px;
  font-weight: $normalFont;

  h3 {
    margin-top: 0px;
  }
}

.list-card-footer {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}
.responds-wrapper {
  display: flex;
}

.responds-count {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: $blue2;
  color: $white;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $mediumFontSize;
  font-weight: $semiBoldFont;
}

.responds-text {
  font-size: $smallFontSize;
  font-weight: $mediumFont;
  color: $orange2;
  width: 59px;
}

.review-card__align {
  display: flex;
  align-items: center;
  margin-right: 8px;
  padding: 0 10px;
  height: 22px;
  font-size: $xsmalFontSize;
  line-height: 120%;
  background: $blue2;
  color: $white;
  font-weight: $mediumFont;
}

.element {
  color: $gray1;
  font-size: $commonFontSize;
  display: flex;
  height: 36px;
  width: 186px;
  align-items: center;
}

.elementImage {
  margin-right: 16px;
  max-width: 20px;
  max-height: 20px;
  width: 100%;
  height: 100%;
}

.btn-cancel {
  color: $gray2;
  font-weight: normal;
  max-width: 150px !important;
  background: $white5 !important;
  width: 100%;
  font-size: $commonFontSize;
  height: 48px;
  margin-right: 16px;
}

.btn-img {
  margin-right: 12px !important;
}

.review-card__profile {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  margin-top: auto;
}

.review-card__star-rating {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  margin-left: 16px;
}

.review-card__username {
  font-weight: $mediumFont;
  font-size: $smallFontSize;
  line-height: 128%;
  color: $orange2;
}

.review-card__rating {
  img {
    margin-right: 8px;
  }
  display: flex;
  align-items: center;
}

.review-card__rating-icons {
  max-width: 8px;
  width: 100%;
  height: 8px;
}

.icon {
  max-width: 40px;
  width: 100%;
  max-height: 40px;
  height: 100%;
}

.request-respond {
  padding: 5px 10px;
  height: 22px;
  background: $green1;
  color: $white;
  font-size: $xsmalFontSize;
  margin-right: 8px;
  line-height: 120%;
  font-weight: $mediumFont;
  display: flex;
  img {
    margin-right: 4px;
  }
}
.request-connect {
  padding: 5px 10px;
  height: 22px;
  background: $blue2;
  color: $white;
  font-size: $xsmalFontSize;
  margin-right: 8px;
  line-height: 120%;
  font-weight: $mediumFont;
  display: flex;
  img {
    margin-right: 4px;
  }
}

.userImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.empty {
  font-size: $smallFontSize;
  font-weight: $mediumFont;
  color: $gray1;
  margin-left: 20px;
  margin-top: 18px;
}

.eye {
  min-width: 50px;
}
.request-connect {
  padding: 5px 10px;
  height: 22px;
  background: $blue2;
  color: $white;
  font-size: $xsmalFontSize;
  margin-right: 8px;
  line-height: 120%;
  font-weight: $mediumFont;
  display: flex;
  img {
    margin-right: 4px;
  }
}

.hideContent {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
