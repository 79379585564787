@import '../../common.scss';

.wrapper {
  display: flex;
  justify-content: center;
}
.tabHeaders {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  background-color: $blue3;
  color: $white;
  top: 0px;
  z-index: 999;
  border-bottom: 1px solid $gray7;
  border-top: 1px solid $gray7;
}
.container {
  max-width: 1084px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.wrapperAccordion {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}
.background {
  background-color: $gray3;
  margin: auto;
}
.backgroundBlue {
  background-color: $blue3;
  padding: 0 15px;
}
.backgroundBlueField {
  background-color: $blue3;
  border: none;
  border-bottom: 1px solid $gray7;
  max-width: 1126px;
  width: 100%;
  color: $white;
  position: relative;
  &::placeholder {
    color: $white1;
  }
}
.headerText {
  font-weight: $semiBoldFont;
  font-size: $headerFontSize;
  color: $white;
}
.green {
  color: $green1;
}
.divContainer {
  max-width: 1084px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 40px;
}

.pTop {
  padding-top: 0px;
}
