@import '../../common.scss';
.padding {
  padding: 0 16px;
  background-color: $white;
}

.chat {
  height: 72px;
  display: flex;
  justify-content: space-between;
  margin: 0 16px 16px;
  cursor: pointer;
}
.active {
  background-color: $gray6;
  height: 72px;
  display: flex;
  justify-content: space-between;
  margin: 0 16px 16px;
  cursor: pointer;
  pointer-events: none;
}
.name {
  padding: 16px 0;
  max-width: 233px;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 16px 0;
  position: relative;
}

.icon {
  width: 40px;
  height: 40px;
  margin-right: 16px;
  border-radius: 44px;
  position: relative;
}

.radius {
  border-radius: 40px;
  max-width: 40px;
  max-height: 40px;
  width: 100%;
  height: 100%;
}

.width {
  max-height: 60px;
  width: 100%;
  height: 100%;
}

.fullName {
  font-size: $smallFontSize;
  color: $black;
  width: 100%;
  font-weight: $mediumFont;
}

.count {
  width: 20px;
  height: 20px;
  border-radius: 16px;
  background-color: $blue2;
  color: $white;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
}

.chatWrapper {
  padding-top: 25px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.chatText {
  font-size: $smallFontSize;
  color: $black;
  width: 100%;
  overflow: hidden;
  font-weight: $normalFont;
  color: $gray1;
}

.img {
  max-width: 48px;
  max-height: 48px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.activitystatus {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  bottom: 0;
  right: 0;
  background: #ff4b55;
}

.onlineStatus {
  background: #41dd8c;
}

.offlineStatus {
  background: #7c7c7c;
}
