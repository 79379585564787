@import '../../common.scss';

.close {
  max-height: 22px;
  max-width: 22px;
  height: 100%;
  width: 100%;
}
.option {
  background-color: $blue;
  color: $white;
  margin: 0px 8px 16px 0;
  padding: 10px 16px;
  align-items: center;
  font-size: $commonFontSize;
  display: flex;
  justify-content: center;
}
