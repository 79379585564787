@import '../../common.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $gray3;
}
.time-subject {
  font-size: $commonFontSize;
}
.request-section {
  max-width: 1158px;
  width: 100%;
  height: 158px;
  margin: 16px 0;
  padding: 0 16px;
  background: $white;
  display: flex;
  flex-direction: column;
}

.mTop {
  margin-top: 0px;
}

.list-title {
  height: 78px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
  line-height: 150%;
  color: $orange2;
}

.color-blue {
  color: $blue2;
}

.request-lists-left {
  max-width: 761px;
  width: 100%;
  padding: 16px;
  background: $white;
  height: 100%;
}

.request-lists-right {
  max-width: 381px;
  width: 100%;
  background: $white;
  padding: 16px 16px 0;
  height: 520px;
}
.padding {
  padding: 16px;
}
.white{
  color: $white;
  font-weight: $normalFont;
}

.request-lists {
  max-width: 1158px;
  width: 100%;
  display: flex;
  margin-bottom: 60px;
  justify-content: space-between;
}

.list-card {
  margin-bottom: 16px;
  border: 1px solid $white4;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.review-card__time-icon {
  margin-right: 5px;
}

.review-card__align-items {
  display: flex;
  align-items: center;
  margin-right: 28px;
  margin-bottom: 16px;
  white-space: nowrap;
  height: 22px;
  font-size: $xsmalFontSize;
  line-height: 120%;
  color: $gray2;
  font-weight: $mediumFont;
}
.list-filter {
  display: flex;
  align-items: center;
  padding: 8px 7px;
  max-width: 127px;
  width: 100%;
  height: 48px;
  margin: 16px 0;
  font-size: $commonFontSize;
  color: $gray2;
  background: $white5;
  cursor: pointer;

  img {
    margin-right: 10px;
  }
}

.pagination {
  display: flex;
  margin-top: 32px;
  justify-content: center;
}

.element {
  color: $gray1;
  font-size: $commonFontSize;
  display: flex;
  width: 96px;
  align-items: center;
}

.white {
  color: $white;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
.cancel-btn {
  background-color: $gray0;
  color: $black;
  margin-top: 10px;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
.margins {
  margin-top: 100px;
}
