@import '../../common.scss';

.contentWrapper {
  display: flex;
  // justify-content: center;
  max-width: 1158px;
  width: 100%;
  margin-top: 16px;
}
.left {
  width: 65%;
  display: flex;
  flex-direction: column;
}
.right {
  width: 35%;
  display: flex;
  flex-direction: column;
}

.list-card {
  margin-bottom: 16px;
  border: 1px solid $white4;
  padding: 16px;
  display: flex;
  background: $white;
  flex-direction: column;
}

.headingWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
  // margin-top: 6px;
}

.divImg {
  margin-right: 16px;
}
.heading {
  font-weight: $mediumFont;
  font-size: $mediumFontSize;
  color: $black;
}
.aboutContent {
  color: $gray1;
  font-size: $commonFontSize;
  margin-top: 22px;
}
.margin {
  margin-bottom: 10px;
}

.label {
  color: $gray2;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  display: flex;
  justify-content: left;
  align-items: center;
}

.pointer {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.section {
  margin-top: 16px;
  margin-left: 16px;
  padding: 16px;
  background-color: $white;
}

.title {
  margin: 16px;
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
  display: flex;
  justify-content: center;
}

.pagination_wrapper {
  display: flex;
  justify-content: center;
  margin: 16px 0 0;
  height: 64px;
  align-items: center;
  width: 100%;
}

.loader {
  height: 170px;
}

.chat-pannel-box {
  width: 100%;
}

.member-section {
  padding: 16px;
  margin-left: 16px;
  margin-bottom: 16px;
  background-color: $white;
}

.team-chat-wrapper {
  flex-direction: column;
  align-items: flex-start;
}

.team-chatting-box {
  width: 100%;
  height: 100%;
}
