@import '../../common.scss';

.element {
  margin-bottom: 16px;
  margin-left: 16px;
  background-color: $white;
  padding: 16px;
}
.divImg {
  margin-right: 16px;
}
.label {
  color: $gray2;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  display: flex;
  justify-content: left;
  align-items: center;
}

.code {
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
  cursor: pointer;
}

.headingWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
  // margin-top: 6px;
}

.pointer {
  cursor: pointer;
}

.marginBottom {
  margin-bottom: 16px;
}
