@import '../../common.scss';

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 16px 0 16px 16px;
  position: relative;
}

.icon {
  width: 40px;
  height: 40px;
  border-radius: 44px;
}

.d-flex {
  display: flex;
  justify-content: flex-start;
}

.chat {
  padding: 9px 16px;
  margin: 16px;
  // overflow: hidden;
  border-radius: 10px;
  font-size: $commonFontSize;
}
.receiver {
  // background-color: $white5;
  background-color: $blue;
  opacity: 0.8;
  color: $white;
  max-width: 499px;
}

.receiverLoading {
  background-color: $gray2;
  max-width: 499px;
  width: 100%;
}

.timeStamp {
  font-size: $xsmalFontSize;
  font-weight: $thinFont;
  margin-top: 6px;
}
.sender {
  border: 1px solid $gray3;
  display: flex;
  flex-direction: column;
  color: $black;
  background-color: #73e5aeb3;
  justify-content: flex-end;
  max-width: 430px;
  width: 100%;
  float: right;
}

.review-card__align-items {
  display: inline-flex;
  align-items: center;
  margin-right: 16px;
  white-space: nowrap;
  height: 22px;
  padding: 0 8px;
  font-size: $xsmalFontSize;
  line-height: 120%;
  color: $gray2;
  background: $white5;
  font-weight: $mediumFont;
}

.review-card__time-icon {
  height: 12px;
  margin-right: 5px;
}

.list-card-title {
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
  line-height: 150%;
  color: $orange2;
  margin-top: 16px;
  max-width: 329px;
}

.list-card-content {
  font-size: $commonFontSize;
  line-height: 160%;
  color: $gray2;
  margin-bottom: 24px;
  font-weight: $normalFont;
}

.review-card__profile {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  margin-top: auto;
}

.review-card__star-rating {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  margin-left: 16px;
}

.review-card__username {
  font-weight: $mediumFont;
  font-size: $smallFontSize;
  line-height: 128%;
  color: $orange2;
}

.review-card__rating {
  display: flex;
  font-size: $smallFontSize;
  align-items: center;
  color: $gray2;
}

.buttonClass {
  min-width: 100px;
  background: $blue2;
  width: 100%;
  max-height: 48px;
  height: 100%;
  color: $white;
  font-weight: normal;
  padding: 15.5px 16px;
  margin-top: 24px;
  font-size: $commonFontSize;
}

.dflex {
  display: flex;
  justify-content: flex-start;
  float: right;
  position: relative;
}

.loader {
  position: absolute;
  bottom: 20%;
  right: 100%;
}

.overflow {
  overflow: hidden;
}

.loadingText {
  font-style: italic;
  color: $gray2;
  display: flex;
  justify-content: flex-start;
}

.text {
  display: flex;
  justify-content: flex-start;
  word-break: break-all;
}

.marginRight {
  margin-right: 16px;
}

.retry {
  cursor: pointer;
}

.proposal-full-size {
  width: 330px;
}

.proposal-loading-issue {
  color: red;
  font-size: 16px;
  font-style: italic;
}

.img {
  max-width: 48px;
  max-height: 48px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;
}
.container {
  border-radius: 50%;
  background: $blue2;
  color: $white;
  height: 100%;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
