@import '../../common.scss';
.divImg {
  margin-right: 16px;
}

.headingWrapper {
  display: flex;
  justify-content: space-between;
}
.labelStudent {
  color: $gray2;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  display: flex;
  justify-content: left;
  padding: 25px 16px;
}
.wrapperStudent {
  max-width: 1158px;
  width: 100%;
  margin-top: 16px;
  background-color: $white;
  margin-bottom: 60px;
}
.feedbackCol {
  display: flex;
  margin-right: 32px;
  background-color: $white;
  border-bottom: 1px solid $gray7;
}
.information {
  display: flex;
  margin: 20px 16px;
  max-width: 340px;
  width: 100%;
}
.imgFeedback {
  width: 40px;
  height: 40px;
  margin-right: 16px;
  border-radius: 44px;
  img {
    border-radius: 44px;
    height: 40px;
    width: 40px;
  }
}
.headingFeedback {
  font-weight: $mediumFont;
  font-size: $mediumFontSize;
  color: $black;
}
.feedbackContent {
  margin: 20px 16px;
}
.feedback {
  margin: 20px 0;
  max-width: 554px;
  width: 100%;
}
.aboutContentFeedback {
  color: $gray1;
  font-size: $commonFontSize;
  margin-top: 6px;
  max-width: 490px;
  width: 100%;
}
.completedOnFeedback {
  color: $gray1;
  font-size: $smallFontSize;
  padding-left: 15px;
  margin-top: 6px;
  max-width: 490px;
  width: 100%;
}
.reviewDate {
  color: $gray1;
  font-size: $smallFontSize;
  max-width: 490px;
  width: 100%;
}
.border {
  margin: 0 16px;
  border-bottom: 1px solid $gray7;
}
.wrapper {
  display: flex;
  justify-content: center;
}
.tab {
  color: $black;
}
.rating {
  display: flex;
  justify-content: center;
}
.imgWrapperIcon {
  background-color: $green1;
  justify-items: center;
  border-radius: 50%;
  height: 130px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.align {
  display: flex;
  justify-content: center;

  font-weight: $semiBoldFont;
  color: $white;
  font-size: $headerFontSize;
}
.imageUrl {
  max-height: 35px;
  max-width: 35px;
  height: 100%;
  width: 100%;
  border-radius: 16px;
}

.name_initials_container {
  width: 40px;
  height: 40px;
}

.star {
  margin-right: 12px;
}

.loader_container {
  padding: 16px;
  background: $white;
}

.title {
  font-size: $smallFontSize;
  color: $gray2;
}
