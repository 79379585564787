@import '../../common.scss';

.container {
  background: $white;
  height: 100%;
  padding: 24px 0 16px 0;
  .heading {
    display: flex;
    align-items: center;
    padding: 0 16px;
    margin-bottom: 42px;
    color: $gray2;
    font-size: $commonFontSize;
    font-weight: $mediumFont;
    .gray_cross {
      width: 16px;
      height: 16px;
      margin-right: 16px;
    }
  }
  .text_area_container {
    padding: 0 16px;
    .textarea {
      resize: none;
      min-height: 100px;
      outline: none;
      border-color: $gray4;
      width: 100%;
      padding: 10px;
    }
    .text_area_help_text {
      display: flex;
      justify-content: flex-end;
      position: relative;
      font-size: $smallFontSize;
      font-weight: $thinFont;
    }
    .text_area_heading {
      font-size: $commonFontSize;
      font-weight: $mediumFont;
      color: $orange2;
      margin-top: 16px;
    }
    .text_area_subHeading {
      font-size: $smallFontSize;
      font-weight: $normalFont;
      color: $gray1;
      margin-bottom: 10px;
    }
  }
  .radio_label {
    border-bottom: 1px solid $gray3;
    padding: 0 16px 0 4px;
    height: 60px;
    margin: 0;
  }
  .label {
    font-size: $commonFontSize !important;
    font-weight: $normalFont !important;
    color: $gray2;
  }
  .submit_button {
    color: $white;
    font-size: $commonFontSize;
    font-weight: $normalFont;
    margin: 32px 0 0 16px;
  }
  .widthSubmit {
    max-width: 250px;
    width: 100%;
  }
  .tick_image {
    margin-right: 16px;
  }
}

.popupImage {
  max-width: 274px;
  width: 100%;
}

.white {
  color: $white;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}

.cancel_btn {
  background: transparent;
  font-size: $commonFontSize;
  color: $black;
  font-weight: $normalFont;
}
.width {
  max-width: 200px;
  width: 100%;
}
.switchWrapper {
  padding-left: 4px;
  display: flex;
  padding-top: 16px;
}
.colFlex {
  display: flex;
  flex-direction: column;
}
.subHeading {
  font-size: $commonFontSize;
  font-weight: $normalFont;
  color: $gray1;
  margin-bottom: 10px;
}
.headingSessionCancel {
  font-size: $commonFontSize;
  font-weight: $mediumFont;
  color: $black;
}
.divImg {
  margin-right: 16px;
  height: 24px;
  margin-left: 12px;
}
.fontSize {
  font-size: $smallFontSize;
}
.border {
  border-bottom: 1px solid $gray3;
  padding-top: 36px;
  padding-bottom: 16px;
}
.errorText {
  color: $red;
  font-size: $smallFontSize;
}
