@import '../../common.scss';

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  background-color: $blue3;
  height: 72px;
  color: $white;
  font-family: 'Poppins', sans-serif;
  position: sticky;
  top: 0px;
  z-index: 999;
}
.logo {
  padding: 0 32px;
}

.home_link {
  display: flex;
  align-items: center;
}

.header-nav {
  display: flex;
  flex-direction: row;
  padding-top: 14px;
  padding-bottom: 15px;
  font-size: $mediumFontSize;
}
.header-tab {
  margin-right: 32px;
  padding: 16px 0px;
  color: $white;
}
.active_tab {
  color: $white;
  opacity: 0.3;
  margin-right: 32px;
  padding: 16px 0px;
}
.header-btns {
  font-family: 'Poppins', sans-serif;
  margin: 12px 32px 12px 0;
  display: flex;
}

.common-btn {
  padding: 0 16px;
  border-radius: 48px;
  border: 1px solid $blue2;
  background-color: $blue3;
  color: $white;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  margin-left: 16px;
  cursor: pointer;
  height: 48px;
}

.log {
  margin-right: 16px;
  height: 16px;
}

.login {
  padding: 16px;
  background-color: $blue3;
  color: $white;
  border: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.displayIcon {
  max-width: 40px;
  max-height: 40px;
  height: 100%;
  width: 100%;
  background-color: $green1;
  border-radius: 50%;
  color: $blue2;
  justify-content: center;
  display: flex;
  cursor: pointer;
}
.verticalAlignment {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.iconMargins {
  margin-right: 16px;
  cursor: pointer;
}
.userIcon {
  max-width: 48px;
  max-height: 48px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;
  z-index: 999;
  padding: 0 !important;
}
.icon {
  display: flex;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $mediumLargeFontSize;
  z-index: 999;
  cursor: pointer;
}
.wrapperRow {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.wrapperCol {
  display: flex;
  justify-content: center;
}
.border {
  border: 2px solid $green1;
  border-radius: 50%;
}
.element {
  color: $gray1;
  font-size: $commonFontSize;
  display: flex;
  justify-content: center;
  max-height: 58px;
  height: 100%;
  margin: 8px;
}
.elementImage {
  margin-right: 16px;
  max-width: 20px;
  max-height: 20px;
  width: 100%;
  height: 100%;
}
.dimensions {
  max-width: 48px;
  max-height: 48px;
  width: 100%;
  height: 100%;
}
.backgroundImg {
  background-color: $blue2;
  border-radius: 50%;
  max-height: 48px;
  max-width: 48px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $mediumLargeFontSize;
}
.wrapperRow {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.wrapperCol {
  display: flex;
  justify-content: center;
}

.label {
  color: $gray2;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  display: flex;
  justify-content: left;
  padding: 6px 0 22px 0;
}

.divImg {
  margin-right: 16px;
}

.marginBtm {
  margin-bottom: 16px;
}

.fullField {
  width: 100%;
  margin-bottom: 16px;
}

.heading {
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  margin-bottom: 8px;
}

.errorMsg {
  color: $red;
  font-size: small;
}

.paddingRight {
  padding-right: 36px;
}

.password {
  width: 100%;
  padding: 16px;
  background-color: $white;
}

.buttonPopup {
  max-width: 101px;
  width: 100%;
  max-height: 48px;
  height: 100%;
  color: $white;
  padding: 15.5px 16px;
  font-size: $commonFontSize;
}

.btnPopup {
  padding: 16px 16px 0px 0;
  display: flex;
  max-width: 1153px;
  width: 100%;
  justify-content: flex-start;
  background-color: $white;
  position: relative;
}
.cancel-btn {
  background-color: $gray0;
  color: $black;
  margin-top: 10px;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
.white {
  color: $white;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}

.formError {
  color: $red;
  font-size: $smallFontSize;
  font-weight: $thinFont;
  position: absolute;
  bottom: 100%;
}

.hamburger-menu {
  visibility: hidden;
  cursor: pointer;
}
.menu {
  left: -100%;
  position: relative;
  width: 375px;
  height: 812px;
  background: #191970;
  // transition: 850ms;
  display: none;
}

.desktopView {
  display: flex;
}

.mobileView {
  display: none;
}

@media screen and (max-width: 768px) {
  .hamburger-menu {
    display: block;
    height: 40px;
    width: 40px;
    margin: 16px;
    visibility: visible;
  }
  .desktopView {
    display: none;
  }

  .mobileView {
    display: flex;
  }
  // .menu {
  //   position: fixed;
  //   top: 0;
  //   right: -100%;
  // }
  // .menuActive {
  //   right: 0;
  //   transition: 850ms;
  //   background: $blue3;
  //   z-index: 1000;
  //   height: 100vh;
  //   width: 100vw;
  //   position: fixed;
  //   overflow: hidden;
  //   margin: 0;
  //   .menu-authenticationPart {
  //     display: flex;
  //     margin: 27px 0 0;
  //     padding-bottom: 32px;
  //     border-bottom: 1px solid $blue5;
  //   }
  //   .common-btn {
  //     height: 48px;
  //     width: 164px;
  //     margin-right: 16px;
  //   }
  // }
  // .close-menu-icon-wrapper {
  //   width: 100%;
  //   border-bottom: 1px solid $blue5;
  //   .close-menu {
  //     width: 40px;
  //     height: 40px;
  //     margin: 16px;
  //     cursor: pointer;
  //   }
  // }
  // .header-tab-mobile-wrapper {
  //   border-bottom: 1px solid $blue5;
  //   padding-bottom: 32px;
  //   padding-top: 32px;
  // }
  // .active_tab_mobile {
  //   padding-left: 16px;
  //   color: #fff;
  //   opacity: 0.3;
  //   cursor: pointer;
  //   font-size: 20px;
  // }
  // .header-tab-mobile {
  //   padding-left: 16px;
  //   cursor: pointer;
  //   color: $white;
  //   margin: 32px 0;
  //   border-bottom: 1px solid $blue5;
  //   font-size: 20px;
  //   padding-bottom: 32px;
  // }
  // .logo {
  //   padding-left: 16px;
  // }
  .menu {
    position: fixed;
    top: 0;
    right: -100%;
  }
  .menuActive {
    right: 0;
    transition: 850ms;
    background: #191970;
    z-index: 1000;
    height: 100vh;
    width: 100vw;
    position: fixed;
    overflow: hidden;
    margin: 0;
    .menu-authenticationPart {
      display: flex;
      flex-direction: row;
      margin: 27px 0 0;
      padding-bottom: 32px;
      border-bottom: 1px solid #2b2b77;
    }
    .common-btn {
      height: 48px;
      width: 164px;
      margin-right: 16px;
    }
  }
  .close-menu-icon-wrapper {
    width: 100%;
    border-bottom: 1px solid #2b2b77;
    .close-menu {
      width: 40px;
      height: 40px;
      margin: 16px;
      cursor: pointer;
    }
  }
  .header-tab-mobile-wrapper {
    border-bottom: 1px solid #2b2b77;
    padding-bottom: 32px;
    padding-top: 32px;
  }
  .active_tab_mobile {
    padding-left: 16px;
    color: #fff;
    opacity: 0.3;
    cursor: pointer;
    font-size: 20px;
  }
  .header-tab-mobile {
    padding-left: 16px;
    cursor: pointer;
    color: #fff;
    font-size: 20px;
  }
  .logo {
    padding-left: 16px;
  }
}

.chat-head {
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  .chat-unread-counter {
    position: absolute;
    left: 27px;
    top: 2px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 50%;
    background-color: #4b4bbc;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 2px;
    box-sizing: content-box;
  }
}
