@import '../../common.scss';

.element {
  margin-bottom: 16px;
  background-color: $white;
  padding: 16px;
}
.widthElement {
  width: 49%;
}
.spaceBetween {
  justify-content: space-between;
}
.timing {
  background-color: $white2;
  max-width: 110px;
  color: $gray2;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 0 10px;
  margin-bottom: 16px;
  font-weight: $mediumFont;
  font-size: $xsmalFontSize;
}
.courseTitle {
  font-weight: $semiBoldFont;
  font-size: $xlargeFontSize;
  color: $orange2;
}
.dates {
  font-size: $commonFontSize;
  color: $gray2;
  padding-bottom: 11px;
}
.owner {
  display: inline-flex;
  justify-content: flex-start;
  padding: 16px 0;
  cursor: pointer;
}
.icon {
  width: 40px;
  height: 40px;
  margin-right: 16px;
  border-radius: 44px;
}
.title {
  font-size: $smallFontSize;
  color: $gray2;
}
.fullName {
  font-size: $smallFontSize;
  color: $black;
  width: 100%;
  font-weight: $mediumFont;
}
.divImg {
  max-width: 16px;
  max-height: 16px;
  width: 100%;
  height: 100%;
  margin-right: 12px;
}
.heading {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 22px;
}
.subHeading {
  color: $gray2;
}
.status {
  color: #e8ab50;
  font-size: $largeFontSize;
}
.statusAbout {
  color: $gray2;
  font-size: 14px;
}
.sessionDetails {
  font-size: 14px;
  font-weight: $normalFont;
  color: $gray2;
}
.connectionFlex {
  display: flex;
  justify-content: flex-start;
}
.connectionNotice {
  margin-right: 12px;
  max-width: 24px;
  max-height: 24px;
  width: 100%;
  height: 100%;
}
.elementSummary {
  background-color: $white;
  padding-top: 16px;
  margin-top: 16px;
}
.headingSummary {
  display: flex;
  justify-content: flex-start;
  padding: 0 16px;
}
.details {
  padding: 16px;
  border-bottom: 1px solid $gray3;
  display: flex;
}
.value {
  margin-left: 82px;
  color: $orange2;
  font-weight: $mediumFont;
}
.headingIcon {
  margin-right: 12px;
  margin-top: 5px;
  max-width: 16px;
  max-height: 16px;
  width: 100%;
  height: 100%;
}
.border {
  border-bottom: 1px solid $gray3;
}
.padding {
  padding: 0 16px;
}
.width {
  max-height: 60px;
  height: 100%;
}
.connection {
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
  margin-bottom: 16px;
}
.attendeeImage {
  border-radius: 44px;
  height: 40px;
  width: 40px;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 16px 16px;
}
.commonBtn {
  margin-right: 16px;
  color: $gray2;
  background-color: $gray3;
  font-weight: $normalFont;
  padding: 16px 8px;
  font-size: $commonFontSize;
}
.white {
  margin-right: 16px;
  color: $white;
  font-weight: $normalFont;
  padding: 16px;
  font-size: $commonFontSize;
}
.disable-button {
  opacity: 0.6;
  cursor: initial;
}
.buttons {
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;
}
.btnImg {
  margin-right: 12px;
}
.smallBtn {
  max-width: 86px;
  width: 100%;
}
.largeBtn {
  max-width: 175px;
  width: 100%;
}
.cancelBtn {
  width: 166px;
}
.maxWidth {
  max-width: 24px;
  width: 100%;
  max-height: 24px;
  height: 100%;
}
.subHeadingSummary {
  color: $gray2;
  max-width: 174px;
  width: 100%;
}
.radius {
  border-radius: 40px;
  max-width: 40px;
  max-height: 40px;
  width: 100%;
  height: 100%;
}
.time {
  height: 12px;
  margin-right: 5px;
}
.d-flex {
  display: flex;
}

.review-card__time-icon {
  height: 12px;
  margin-right: 5px;
}

.review-card__align-items {
  display: flex;
  align-items: center;
  margin-right: 8px;
  height: 22px;
  padding: 0 8px;
  white-space: nowrap;
  font-size: $xsmalFontSize;
  line-height: 120%;
  font-weight: $mediumFont;
}
.gray {
  color: $gray2;
  background: $white5;
}
.greenTag {
  color: $white;
  background: $green1;
}
.courseTitle {
  padding-top: 16px;
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
}
.marginBtm {
  margin-bottom: 0 !important;
}
.acceptButton {
  padding: 16px;
  max-width: 235px;
  width: 100%;
}
.form_loader {
  margin-right: 10px;
}
.green {
  background: $green1;
  color: $white;
  font-size: $commonFontSize;
  font-weight: normal;
}
.widthPopup {
  max-width: 274px;
  width: 100%;
}
.mediumBtn {
  max-width: 159px;
  width: 100%;
}
.sessionStatus {
  color: $green1;
  font-size: $largeFontSize;
  font-weight: $semiBoldFont;
}
.blue {
  color: $blue;
}
.red {
  color: $red1;
  font-weight: $semiBoldFont;
}
.blue {
  color: $blue;
  font-size: $largeFontSize;
  font-weight: $semiBoldFont;
}
.blueTag {
  background-color: $blue;
  color: $white;
}
.red {
  color: $red1;
  font-size: $largeFontSize;
  font-weight: $semiBoldFont;
}
.redTag {
  background-color: $red1;
  color: $white;
}
.statusFin {
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
}
.In-Review {
  color: #e8ab50;
}
.Payed {
  color: #41dd8c;
}
.Rejected {
  color: #ff4b55;
}
.wrapper {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
}
.fullNameBold {
  font-size: $commonFontSize;
  font-weight: $mediumFont;
}
.paddingTop {
  padding-top: 16px;
}
.green-label {
  color: $white;
  background: $green1;
}

.red-label {
  color: $white;
  background: $red1;
}

.blue-label {
  color: $white;
  background: $blue2;
}

.zeroMinWidth {
  min-width: 0 !important;
}

.opacity {
  opacity: 0.3;
}
.marginTop {
  margin-top: 16px;
}

.imgCheckbox {
  margin-left: 25px;
}

.red-patch {
  background: $red2;
  color: $red3;
}

.widthProposal {
  min-width: 240px !important;
}

.mTop {
  margin-top: 16px;
}

.proposal_cancel_btn {
  background: transparent;
  font-size: $commonFontSize;
  color: $black;
  font-weight: $normalFont;
}
.link {
  color: $green1;
  font-size: $commonFontSize;
}
.linkDiv {
  display: flex;
  font-size: $commonFontSize;
}
.marginRight {
  margin-right: 5px;
}

.cursor {
  cursor: pointer;
  width: 100%;
}

.dFlex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.disable{
  cursor: initial;
}