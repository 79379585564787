@import '../../common.scss';

.contentWrapper {
  display: flex;
  // justify-content: center;
  max-width: 1158px;
  width: 100%;
}
.left {
  width: 60%;
  display: flex;
  flex-direction: column;
}
.right {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.outerWrapper {
  max-width: 761px;
  padding-left: 16px;
  width: 100%;
  background-color: $white;
}
.wrapperForJoin {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $gray3;
  padding: 16px;
  padding-left: 0 !important;
}

.joinSection {
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
}
.iconWrapper {
  max-width: 40px;
  max-height: 40px;
  height: 100%;
  width: 100%;
  margin-right: 16px;
  margin-top: 5px;
}
.icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.userName {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  font-size: 12px;
  min-width: 200px;
}

.acceptButton {
  max-width: 114px;
  width: 100%;
  margin-right: 16px;
  padding: 16px;
  color: $white;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}

.cross {
  padding: 16px;
  background-color: $gray3;
  max-height: 48px;
  max-width: 48px;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.margin {
  margin-right: 16px;
  width: 40px;
}

@import '../../common.scss';

.background {
  background-color: $gray3;
  height: 100%;
  width: 100%;
  padding-top: 16px;
}

.maxWidth {
  max-width: 1153px;
  background-color: $white;
}

.right {
  width: 40%;
}
.center {
  display: flex;
  justify-content: center;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 1158px;
  width: 100%;
  margin-top: 16px;
  background-color: $white;
}

.imgWrapper {
  max-width: 130px;
  width: 100%;
  margin: 16px 32px 16px 16px;
  position: relative;

  label {
    position: absolute;
    right: 50px;
    bottom: 50px;
  }
}

.userImg {
  width: 126px;
  height: 126px;
  border-radius: 50%;
}

.imgWrapperIcon {
  background-color: $green1;
  justify-items: center;
  border-radius: 50%;
  height: 130px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.align {
  display: flex;
  justify-content: center;
  font-weight: $semiBoldFont;
  color: $white;
  font-size: $headerFontSize;
}

.loader {
  position: absolute;
  top: 60%;
  left: 66%;
  max-height: 48px;
  max-width: 48px;
  height: 100%;
  width: 100%;
  background-color: $blue;
  border-radius: 50%;
  align-items: center;
  padding-left: 10px;
}

.userInfoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.name {
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
}

.tabsWrapper {
  max-width: 1158px;
  width: 100%;
  max-height: 68px;
  height: 100%;
  background-color: $white;
  padding-left: 16px;
}

.tab {
  color: $black;
}

.right {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.hourRate {
  margin-bottom: 16px;
  margin-left: 16px;
  background-color: $white;
  padding: 16px;
}

.divImg {
  margin-right: 16px;
}

.headingWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
  // margin-top: 6px;
}

.label {
  color: $gray2;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  display: flex;
  justify-content: left;
  align-items: center;
}

.pointer {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.costContent {
  color: $gray1;
  font-size: $smallFontSize;
}

.costPerHour {
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
  color: $blue2;
}

.LanguageHeadingWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
  margin-top: 16px;
  padding-left: 16px;
}
.LanguagesKnown {
  margin-bottom: 16px;
  margin-left: 16px;
  background-color: $white;
}
.languagePointer {
  cursor: pointer;
  align-items: center;
  padding-right: 16px;
}
.subHeadingWrapper {
  margin-top: 11px;
  display: flex;
  justify-content: space-between;
  padding: 0 16px 16px 16px;
  border-bottom: 1px solid $gray3;
}

.labelInner {
  color: $gray2;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  display: flex;
  justify-content: left;
  padding-top: 10px;
}
.inner {
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  padding-top: 10px;
}

.membersList-container {
  display: flex;
  justify-content: flex-start;
  max-width: 1158px;
  width: 100%;
  margin-bottom: 16px;
}

.section {
  max-width: 761px;
  width: 100%;
  margin-bottom: 16px;
  background: $white;
  &:last-child {
    margin-bottom: 0px;
  }
}

.search_section {
  padding: 24px 16px 16px;
  max-width: 729px;
  width: 100%;
  min-height: 340px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .search_heading {
    color: $orange2;
    font-size: $largeFontSize;
    font-weight: $semiBoldFont;
    padding: 0 16px;
    margin-bottom: 8px;
  }

  .request_count {
    color: $blue2;
  }
}

.invite_btn {
  width: 143px !important;
  background: $blue2 !important;
  font-size: $commonFontSize !important;
  font-weight: $normalFont !important;
  color: $white !important;
  margin: 16px 0;
}

.settings_btn {
  width: 138px !important;
  background: $white5 !important;
  font-size: $commonFontSize !important;
  font-weight: $normalFont !important;
  color: $gray2 !important;
  padding: 16px;
}

.user_img {
  margin-right: 16px;
}

.members {
  max-width: 729px;
  width: 100%;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $gray5;
}

.membersList-userDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 32px;
  margin-left: 16px;
}

.membersList-username {
  font-weight: $mediumFont;
  font-size: $smallFontSize;
  line-height: 128%;
  color: $orange2;
}

.membersList-role {
  font-weight: $normalFont;
  font-size: $smallFontSize;
  line-height: 128%;
  color: $orange2;
}

.membersList-profile {
  width: 100%;
  max-width: 150px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.user_Img {
  height: 40px;
  width: 40px;
  justify-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.pagination_wrapper {
  display: flex;
  justify-content: center;
  margin: 16px 0 0;
  height: 64px;
  align-items: center;
  width: 100%;
}

.membersList-actions {
  display: flex;
}

.delete_img {
  margin-left: 16px;
  cursor: pointer;
}

.red {
  color: $white;
  background: $red1;
  font-size: $commonFontSize;
  font-weight: normal;
}

.cancel-btn {
  background-color: $gray0;
  color: $black;
  font-size: $commonFontSize;
  margin-top: 10px;
  font-weight: normal;
}

.memberTop {
  border-bottom: 1px solid $gray5;
}

.pink {
  color: $pink1;
}
.element {
  background-color: $white;
  padding: 16px;
  margin-left: 16px;
  max-width: 381px;
  width: 100%;
  height: 100%;
}

.title {
  margin-top: 6px;
  margin-bottom: 22px;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  display: flex;
  justify-content: space-between;
  color: $gray2;
}
.heading {
  display: flex;
  justify-content: flex-start;
}

.code {
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
}

.editImgUploader,
.imgUploader {
  position: absolute;
  cursor: pointer;
}

.flexBody {
  display: flex;
  width: 50%;
}

.editTeamName {
  padding: 20px;
  img {
    cursor: pointer;
  }
}

.right {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.invites {
  background-color: $white;
  margin-bottom: 16px;
}

.joinRequestTitle {
  padding: 24px 32px;
  font-size: $largeFontSize;
  font-weight: $semiBoldFont;
}

.pagination {
  display: flex;
  margin-bottom: 24px;
  justify-content: center;
}

.green {
  color: $white;
  font-weight: $normalFont;
  font-size: $commonFontSize;
}

.white {
  color: $white;
  font-weight: $normalFont;
  font-size: $commonFontSize;
}

.white {
  color: $white;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
.marginBtm {
  margin-bottom: 16px;
}
