@import '../../common.scss';

.outerWrapper {
  padding: 16px;
  background-color: $white;
}

.list-card-title {
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  line-height: 160%;
  color: $orange2;
  margin: 8px 0;
}

.list-card-dropdown {
  margin-bottom: 16px;
}

.textarea-styles {
  width: 100%;
  padding: 15px;
  line-height: 19px;
  height: 100px;
  font-size: $mediumFontSize;
  resize: none;
  border: 1px solid $gray4;
  outline-style: none;
}

.character-count {
  font-weight: $thinFont;
  font-size: $smallFontSize;
  line-height: 120%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: right;
  color: $gray1;
}

.label {
  font-size: $commonFontSize;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.dFlex {
  display: flex;
  justify-content: flex-start;
}

.buttonWrapper {
  max-width: 150px;
  width: 100%;
  margin-top: 32px;
}

.button {
  font-size: $commonFontSize;
  font-weight: $normalFont;
  color: $white;
  background-color: $red1;
}

.teamDetails {
  font-size: $largeFontSize;
  font-weight: $semiBoldFont;
  margin-bottom: 16px;
}

.errorText {
  color: $red;
  font-size: $smallFontSize;
}
.white {
  color: $white;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
.cancel-btn {
  color: $white;
  background-color: $red1;
  font-weight: $normalFont;
  font-size: $commonFontSize;
}
