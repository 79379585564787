@import '../../common.scss';

.desc {
  font-size: $commonFontSize;
  font-weight: $normalFont;
  color: $gray1;
}

.dflex {
  display: flex;
  justify-content: space-between;
}

.heading {
  font-weight: 600;
  font-size: 20px;
}
.element {
  color: $gray1;
  font-size: $commonFontSize;
  display: flex;
  height: 36px;
  width: 186px;
  align-items: center;
}

.elementImage {
  margin-right: 12px;
  max-width: 14px;
  max-height: 20px;
  width: 100%;
  height: 100%;
}

.marginTop {
  margin-top: 16px;
}
.myTeamWrapper {
  border: 1px solid $white4;
  padding: 16px;
  margin-bottom: 8px;
  cursor: pointer;
}

.white {
  color: $white;
  font-weight: $normalFont;
  font-size: $commonFontSize;
}

.green {
  color: $white;
  font-weight: $normalFont;
  font-size: $commonFontSize;
}

.cancel {
  background-color: $gray0;
  color: $black;
  font-size: $commonFontSize;
  margin-top: 10px;
  font-weight: normal;
}

.members {
  margin-top: 24px;
  display: flex;
  justify-content: flex-start;
}

.icon {
  max-width: 40px;
  width: 100%;
  height: 40px;
  border-radius: 16px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
.icon:nth-child(2) {
  right: 15px;
}
.icon:nth-child(3) {
  right: 25px;
}
.icon:nth-child(4) {
  right: 35px;
}
.icon:nth-child(5) {
  right: 45px;
}
.icon:nth-child(6) {
  right: 55px;
}
.container {
  border-radius: 50%;
  background: $blue2;
  color: $white;
  height: 100%;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.flex {
  display: flex;
  justify-content: flex-start;
}
.button {
  max-width: 114px;
  width: 100%;
  color: $white;
  font-size: 14px;
  font-weight: 400;
  margin-top: 32px;
  margin-right: 16px;
}

.grey {
  max-width: 114px;
  width: 100%;
  color: $gray1;
  background-color: $white4;
  font-size: 14px;
  font-weight: 400;
  margin-top: 32px;
  margin-right: 16px;
}

.divImg {
  margin-right: 16px;
}

.widthCancelRequest {
  max-width: 168px;
}

.marginRight {
  margin-right: 16px;
}

.iconSize {
  width: 40px;
  height: 40px;
  border-radius: 44px;
}
