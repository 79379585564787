@import '../../common.scss';

.contentWrapper {
  min-width: 1158px;
  width: 100%;
  margin: 16px 0;
  padding-top: 16px;
  background-color: $white;
}

.heading {
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
  padding-left: 32px;
  padding-top: 8px;
}

.padding {
  padding: 16px;
}
.search {
  width: 100%;
}

.dFlex {
  display: flex;
  justify-content: flex-start;
  padding: 16px 0;
}

.border {
  border-top: 1px solid $gray3;
}

.userDetails {
  display: flex;
  justify-content: flex-start;
  max-width: 450px;
  width: 100%;
}

.icon {
  width: 40px;
  height: 40px;
  margin-right: 16px;
}

.name {
  font-weight: $mediumFont;
  font-size: $smallFontSize;
}
.desc {
  font-weight: $normalFont;
  font-size: $smallFontSize;
  color: $gray2;
}

.courseDetails {
  max-width: 500px;
  width: 100%;
}

.courseTitle {
  font-weight: $semiBoldFont;
  font-size: $mediumFontSize;
}

.star {
  margin-right: 12px;
}

.element {
  color: $gray1;
  font-size: $commonFontSize;
  display: flex;
  min-width: 96px;
  width: 100%;
  align-items: center;
}

.dropdown_container {
  height: 48px;
  margin-left: 16px;
  &:first-child {
    margin-right: 16px;
  }
}

.list-filter {
  display: flex;
  align-items: center;
  padding: 8px 6px;
  max-width: 127px;
  width: 100%;
  font-size: $commonFontSize;
  color: $gray2;
  background: $white5;
  cursor: pointer;
  height: 48px;
  img {
    margin-right: 10px;
  }
}
