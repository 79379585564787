@import '../../common.scss';

.whiteBackground {
  background-color: $white;
  min-height: 100px;
}
.heading {
  display: flex;
  justify-content: flex-start;
  padding: 24px 16px;
  color: $gray2;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
}

.divImg {
  margin-right: 16px;
}

.withdrawalMethod {
  padding: 16px;
}

.boldText {
  font-size: $commonFontSize;
  font-weight: $semiBoldFont;
}

.smallText {
  font-weight: $normalFont;
  font-size: $smallFontSize;
}

.marginTop {
  margin-top: 8px;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-start;
  margin-left: 16px;
  padding-bottom: 16px;
}

.button {
  color: $white;
  max-width: 158px;
  width: 100%;
  font-size: $commonFontSize;
  font-weight: $normalFont;
  margin-right: 16px;
}

.greyButton {
  color: $gray2;
  background-color: $white5;
  max-width: 185px;
  width: 100%;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}

.radioLabel {
  border-bottom: 1px solid $gray3;
  padding: 0 16px;
}
.label {
  font-size: $commonFontSize !important;
  font-weight: $normalFont !important;
  color: $gray2;
}

.other {
  padding: 16px;
}

.paymentTypes {
  padding: 15px 0;
}

.green{
  color: $white;
  font-weight: $normalFont;
}