@import '../../common.scss';

.list-card {
  margin-bottom: 16px;
  border: 1px solid $white4;
  padding: 16px;
  display: flex;
  background: $white;
  flex-direction: column;
}

.review-card__time-icon {
  height: 12px;
  margin-right: 12px;
}

.review-card__align-items {
  display: flex;
  align-items: center;
  margin-right: 28px;
  white-space: nowrap;
  height: 22px;
  font-size: $commonFontSize;
  line-height: 120%;
  color: $gray2;
  background: $white5;
  font-weight: $mediumFont;
  margin-bottom: 24px;
}

.list-card-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.d-flex {
  display: flex;
}
.edit-title {
  font-style: normal;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  line-height: 160%;
  color: $orange2;
}

.textarea-styles {
  width: 100%;
  margin-top: 8px;
  padding: 15px;
  line-height: 19px;
  height: 100px;
  font-size: $mediumFontSize;
  resize: none;
  border: 1px solid $gray4;
  outline-style: none;
}

.character-count {
  font-weight: $thinFont;
  font-size: $smallFontSize;
  line-height: 120%;
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 16px;
  justify-content: right;
  color: $gray1;
}

.edit-title-gray {
  color: $gray1;
  margin-bottom: 8px;
  font-size: $commonFontSize;
}

.box-btn {
  width: 99px;
}

.btn-color {
  color: $white !important;
  width: 100% !important;
  font-size: $commonFontSize !important;
  cursor: pointer;
  height: 48px !important;
  outline: none !important;
  box-shadow: none;
  margin-top: 32px;
}

.btn-img {
  margin-right: 16px;
}

.error {
  font-size: $smallFontSize;
  color: $red;
  font-weight: $thinFont;
  position: absolute;
  top: 100%;
  margin-bottom: 10px;
}
.autocompletePosition {
  position: relative;
}

.divImg {
  margin-right: 10px;
}

.mTop {
  margin-top: 10px;
}
