@import '../../common.scss';

.info {
  display: flex;
  justify-content: center;
}
.personalInfo {
  max-width: 1153px;
  width: 100%;
  padding: 16px;
  background-color: $white;
}
.password {
  max-width: 381px;
  width: 100%;
  padding: 16px;
  background-color: $white;
}
.interests {
  max-width: 1153px;
  width: 100%;
  margin-top: 16px;
  background-color: $white;
}
.btnWraper {
  display: flex;
  justify-content: center;
}
.button {
  padding: 16px;
  margin-top: 16px;
  display: flex;
  max-width: 1153px;
  width: 100%;
  justify-content: flex-start;
  background-color: $white;
}
.btnPopup {
  padding: 0 16px 16px 0;
  display: flex;
  max-width: 1153px;
  width: 100%;
  justify-content: flex-start;
  background-color: $white;
}
.label {
  color: $gray2;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  display: flex;
  justify-content: left;
  padding: 6px 0 22px 0;
}
.labelSkills {
  color: $gray2;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  display: flex;
  justify-content: left;
  // margin-bottom: 8px;
}
.divImg {
  margin-right: 16px;
}
.heading {
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  margin-bottom: 8px;
}
.wrapper {
  display: flex;
  justify-content: center;
}

.eachSide {
  width: 50%;
  margin-bottom: 16px;
  &:first-child {
    margin-right: 16px;
  }
}
.fullField {
  width: 100%;
  margin-bottom: 16px;
}

.buttonClass {
  max-width: 121px;
  width: 100%;
  max-height: 48px;
  height: 100%;

  color: $white;
  padding: 15.5px 16px;

  font-size: $commonFontSize;
}

.buttonPopup {
  max-width: 101px;
  width: 100%;
  max-height: 48px;
  height: 100%;
  color: $white;
  padding: 15.5px 16px;
  font-size: $commonFontSize;
}

.error {
  font-size: $smallFontSize;
  color: $red;
  font-weight: $thinFont;
  position: absolute;
  top: 100%;
}
.marginBtm {
  margin-bottom: 16px;
}
.cost {
  border-bottom: 1px solid $gray4;
}

.phone_number_wrapper {
  display: flex;
  & > div {
    &:first-child {
      position: relative;
      margin-right: 11px;
    }
  }
}

.phone_number {
  width: 100%;
}
.cancel-btn {
  background-color: $gray0;
  color: $black;
  margin-top: 10px;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
.white {
  color: $white;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
.phoneno {
  width: 100%;
}

.close {
  max-height: 22px;
  max-width: 22px;
  height: 100%;
  width: 100%;
}
.color {
  color: $blue2;
}

.about {
  max-height: 396px;
  height: 100%;
  padding: 16px;
  background-color: $white;
}
.headingWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.interestWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.multiSelectWrapper {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  flex-wrap: wrap;
}
.wrapperAccountSetting {
  margin-top: 16px;
  padding-bottom: 60px;
}
.errorMsg {
  color: $red;
  font-size: small;
}

.list-card {
  margin-bottom: 16px;
  border: 1px solid $white4;
  padding: 0 16px;
  display: flex;
  background: $white;
  flex-direction: column;
}

.heading2 {
  font-weight: $mediumFont;
  font-size: $commonFontSize;
}

.subHeading {
  font-size: $smallFontSize;
  color: $gray2;
  margin-bottom: 8px;
}

.autocompletePosition {
  position: relative;
  margin-bottom: 16px;
}

.pointer {
  cursor: pointer;
}
.paddingRight {
  padding-right: 36px;
}
.form_loader {
  margin-right: 10px;
}
.aboutContent {
  font-size: $smallFontSize;
  color: $gray2;
  margin-top: 10px;
}
.country_wrapper{
  display: flex;
  .eachSide{
    max-width: 553px;
    width: 100%;
  }
}