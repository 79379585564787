@import '../../common.scss';
.heading {
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
  line-height: 150%;
  text-align: center;
  margin-bottom: 16px;
}
.subHeading {
  font-size: $commonFontSize;
  line-height: 120%;
  font-weight: $normalFont;
  color: $gray1;
  text-align: center;
  margin-bottom: 53px;
}

.formHeading {
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  margin-bottom: 8px;
}
.field {
  margin-bottom: 16px;
}
.btn {
  color: $white;
  margin: 32px 0 6px;
  font-size: $commonFontSize;
}
.cancel {
  font-weight: $normalFont;
  padding-top: 16px;
  color: $gray1;
  background-color: $gray3;
  font-size: $commonFontSize;
  text-align: center;
}
.forgetPassForm {
  max-width: 274px;
  max-height: 333px;
  width: 100%;
  height: 100%;
}
.link {
  text-align: center;
  font-size: $commonFontSize;
}
.white {
  color: $white;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
.cancel-btn {
  background-color: $gray0;
  color: $black;
  margin-top: 10px;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
