@import '../../common.scss';

.section {
  width: 1158px;
  margin-bottom: 16px;
  background-color: $white;
  padding: 16px;
}

.marginTop {
  margin-top: 8px;
  margin-bottom: 24x;
}

.row {
  display: flex;
  justify-content: flex-start;
}

.heading {
  font-size: $largeFontSize;
  font-weight: $semiBoldFont;
}

.count {
  color: $gray1;
}

.countBlue {
  color: $blue2;
}

.paddingTop {
  padding-top: 16px;
}

.newTeam {
  max-width: 187px;
  width: 100%;
  margin-right: 16px;
}
.buttonStyles {
  font-size: 14px;
  color: white;
  padding: 16px;
  font-weight: 400;
}

.buttonGrey {
  background-color: $white5;
  color: $gray2;
  font-weight: 400;
  font-size: 14px;
  padding: 16px;
  max-width: 135px;
  width: 100%;
}

.divImg {
  margin-right: 8px;
}

.marginLeft {
  margin-left: 16px;
}

.empty {
  display: flex;
  justify-content: center;
  margin: 150px 0;
}

.width {
  max-width: 275px;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 16px;
}

.desc {
  font-size: $commonFontSize;
  font-weight: $normalFont;
  color: $gray1;
}

.myTeamWrapper {
  border: 1px solid $white4;
  padding: 16px;
  margin-bottom: 8px;
}

.members {
  margin-top: 24px;
  display: flex;
  justify-content: flex-start;
}

.icon {
  max-width: 40px;
  width: 100%;
  height: 40px;
  border-radius: 16px;
}

.relative {
  position: relative;
  right: 10px;
}

.relative1 {
  position: relative;
  right: 15px;
}

.relative2 {
  position: relative;
  right: 20px;
}

.relative3 {
  position: relative;
  right: 25px;
}
.relative4 {
  position: relative;
  right: 30px;
}

.container {
  border-radius: 50%;
  background: $blue2;
  color: $white;
  height: 100%;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.pagination {
  display: flex;
  margin-bottom: 24px;
  justify-content: center;
}

.textCenter{
  text-align: center;
}