@import '../../common.scss';

.wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  background-color: $white;
  margin-bottom: 16px;
}

.imgWrapper {
  max-width: 130px;
  width: 100%;
  margin: 16px 32px 16px 16px;
  position: relative;
}

.userImg {
  width: 126px;
  height: 126px;
  border-radius: 50%;
}

.imgWrapperIcon {
  background-color: $green1;
  justify-items: center;
  border-radius: 50%;
  height: 130px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.align {
  display: flex;
  justify-content: center;
  font-weight: $semiBoldFont;
  color: $white;
  font-size: $headerFontSize;
}

.userInfoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.name {
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
}

.manageRights {
  display: flex;
  align-items: center;
  padding: 22px 0 22px 16px;
}

.manageRights-img {
  margin-right: 12px;
}

.manageRights-title {
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  line-height: 150%;
  color: $gray2;
}

.invite_btn {
  width: 99px !important;
  font-size: $commonFontSize !important;
  font-weight: $normalFont !important;
  color: $white !important;
  margin: 30px 16px 16px;
}

.pLeft {
  flex-direction: column;
}

.user_img {
  margin-right: 12px;
}

.switchWrapper {
  width: 100%;
  background-color: $white;
  padding: 16px 0;
  font-size: $commonFontSize;
  border-bottom: 1px solid $gray5;
}

.borders {
  width: 100%;
  max-width: 423px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding-left: 16px;
  color: $orange2;
}

.marginBottom {
  margin-right: 12px;
}

.rating {
  display: flex;
  justify-content: center;
}

.star {
  margin-right: 12px;
}

.feedbackContent {
  margin: 5px 0;
}

.red {
  color: $white;
  background-color: $red1;
  margin-bottom: 16px;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}

.white {
  color: $gray2;
  background-color: $gray0;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
