@import '../../common.scss';

.members {
  max-width: 729px;
  width: 100%;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $gray5;
}

.membersList-userDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 32px;
  margin-left: 16px;
}

.membersList-username {
  font-weight: $mediumFont;
  font-size: $smallFontSize;
  line-height: 128%;
  color: $orange2;
}

.membersList-role {
  font-weight: $normalFont;
  font-size: $smallFontSize;
  line-height: 128%;
  color: $orange2;
}
.pink {
  color: $pink1;
}
.membersList-profile {
  width: 100%;
  max-width: 150px;
  height: 40px;
  display: flex;
  align-items: center;
}

.user_Img {
  height: 40px;
  width: 40px;
  justify-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.sessions {
  color: $gray2;
  font-size: $commonFontSize;
}

.blue {
  color: $blue;
}
