@import '../../common.scss';

.list-card {
  margin-bottom: 16px;
  border: 1px solid $white4;
  padding: 16px;
  display: flex;
  background: $white;
  flex-direction: column;
}

.list-card-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.list-card-title {
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
  line-height: 150%;
  color: $orange2;
  margin-top: 16px;

  h3 {
    margin: 0px;
  }
}

.list-card-content {
  font-size: $commonFontSize;
  line-height: 160%;
  color: $gray2;

  h3 {
    margin-top: 0px;
  }
}

.list-card-description {
  font-size: $commonFontSize;
  line-height: 160%;
  color: $gray1;
}

.list-card-footer {
  margin-top: 24px;
  display: flex;
}

.d-flex {
  display: flex;
}

.review-card__align-items {
  display: flex;
  align-items: center;
  margin-right: 28px;
  height: 22px;
  white-space: nowrap;
  font-size: $xsmalFontSize;
  line-height: 120%;
  background: $white5;
  color: $gray2;
  font-weight: $mediumFont;
}

.review-card__time-icon {
  height: 12px;
  margin-right: 5px;
}

.btn-cancel {
  color: $gray2;
  font-weight: normal;
  max-width: 150px !important;
  background: $white5 !important;
  width: 100%;
  font-size: $commonFontSize;
  height: 48px;
  margin-right: 16px;
}

.btn-img {
  margin-right: 12px !important;
}

.request-section {
  max-width: 1158px;
  width: 100%;
  min-height: 158px;
  margin: 16px 0;
  padding: 0 16px;
  background: $white;
  display: flex;
  flex-direction: column;
}

.list-title {
  height: 78px;
  display: flex;
  align-items: center;
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
  line-height: 150%;
  color: $orange2;
}

.list-title-common {
  height: 65px;
  display: flex;
  align-items: center;
  font-size: $commonFontSize;
  line-height: 150%;
  color: $gray2;

  img {
    margin-right: 12px;
  }
}

.color-blue {
  color: $blue2;
}

.list-filter {
  display: flex;
  align-items: center;
  padding: 8px 7px;
  max-width: 127px;
  width: 100%;
  height: 48px;
  margin: 16px 0;
  font-size: $commonFontSize;
  color: $gray2;
  background: $white5;
  cursor: pointer;

  img {
    margin-right: 10px;
  }
}

.review-card__profile {
  width: 170px;
  height: 40px;
  display: flex;
  align-items: center;
  margin: 24px 0px 16px;
  cursor: pointer;
}

.review-card__star-rating {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 32px;
  margin-left: 16px;
}

.review-card__username {
  font-weight: $semiBoldFont;
  font-size: $smallFontSize;
  line-height: 128%;
  color: $orange2;
}

.review-card__rating {
  img {
    margin-right: 8px;
  }
  display: flex;
  align-items: center;
}

.backgroundImg {
  background-color: $blue2;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  color: $white;
  justify-content: center;
  align-items: center;
  font-weight: $semiBoldFont;
  font-size: $mediumFontSize;
}

.icon {
  max-width: 40px;
  width: 100%;
  max-height: 40px;
  height: 100%;
}

.connect {
  padding-top: 16px;
}

.btn-connect {
  width: 120px;
  color: $white;
  background: $blue;
  height: 48px;
  font-size: $commonFontSize;
}

.connect-img {
  margin-right: 8px;
}

.element {
  color: $gray1;
  font-size: $commonFontSize;
  display: flex;
  width: 96px;
  align-items: center;
}

.edit-title {
  font-style: normal;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  line-height: 160%;
  color: $orange2;
}

.textarea-styles {
  width: 100%;
  margin-top: 8px;
  padding: 15px;
  line-height: 19px;
  height: 100px;
  font-size: $mediumFontSize;
  resize: none;
  border: 1px solid $gray4;
  outline-style: none;
}

.character-count {
  font-weight: $thinFont;
  font-size: $smallFontSize;
  line-height: 120%;
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 16px;
  justify-content: right;
  color: $gray1;
}

.edit-title-gray {
  color: $gray1;
  margin-bottom: 8px;
  font-size: $commonFontSize;
}

.headingCost {
  font-weight: $mediumFont;
  font-size: $commonFontSize;
}

.subHeadingRate {
  font-size: $smallFontSize;
  color: $gray2;
}

.cost {
  border-bottom: 1px solid $gray3;
}

.smallField {
  max-width: 45px;
}

.wrapperLeft {
  display: flex;
  justify-content: left;
  margin-top: 8px;
}

.dolar {
  font-weight: $boldFont;
}

.halfField {
  max-width: 248px;
  width: 100%;
}

.buttonLeft {
  display: flex;
  max-width: 1153px;
  margin: 32px 0 16px;
  width: 100%;
  justify-content: flex-start;
  background-color: $white;
}

.buttonLeft1 {
  display: flex;
  // max-width: 1153px;
  margin: 8px 0 16px;
  // width: 100%;
  justify-content: flex-start;
  background-color: $white;
  margin-right: 10px;
}

.buttonClass {
  max-width: 162px;
  width: 100%;
  max-height: 48px;
  height: 100%;
  font-weight: normal;
  color: $white;
  padding: 15.5px 14px;
  font-size: $commonFontSize;
}

.divImg {
  margin-right: 16px;
}

.error {
  font-size: $smallFontSize;
  color: $red;
  font-weight: $thinFont;
  top: 100%;
}

.request-respond {
  padding: 5px 10px;
  height: 22px;
  background: $green1;
  color: $white;
  font-size: $xsmalFontSize;
  margin-right: 8px;
  line-height: 120%;
  font-weight: $mediumFont;
  display: flex;
  img {
    margin-right: 4px;
  }
}
.request-connect {
  padding: 5px 10px;
  height: 22px;
  background: $blue2;
  color: $white;
  font-size: $xsmalFontSize;
  margin-right: 8px;
  line-height: 120%;
  font-weight: $mediumFont;
  display: flex;
  img {
    margin-right: 4px;
  }
}
.buttonWithdraw {
  background: $white5;
  max-width: 189px;
  width: 100%;
  max-height: 48px;
  font-weight: normal;
  color: $gray2;
  padding: 8px 12px;
  font-size: $commonFontSize;
  line-height: 120%;
}

.review-card__timer {
  color: $gray1;
  font-weight: normal;
  line-height: 160%;
  font-size: $smallFontSize;
}

.userImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.text_area_container {
  position: relative;
}

.initials_container {
  width: 40px;
  height: 40px;
}
.empty {
  font-size: $smallFontSize;
  font-weight: $mediumFont;
  color: $gray1;
  margin-left: 20px;
  margin-top: 18px;
  position: absolute;
  top: 70%;
  left: 1.5%;
}
.btnWrapper {
  display: flex;
  justify-content: flex-start;
}

.eye {
  min-width: 50px;
}

.request-connect {
  padding: 5px 10px;
  height: 22px;
  background: $blue2;
  color: $white;
  font-size: $xsmalFontSize;
  margin-right: 8px;
  line-height: 120%;
  font-weight: $mediumFont;
  display: flex;
  img {
    margin-right: 4px;
  }
}
.green {
  background: $green1;
  color: $white;
  font-size: $commonFontSize;
  font-weight: normal;
}
