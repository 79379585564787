@import '../../common.scss';

.section {
  width: 1158px;
  margin-bottom: 16px;
  background-color: $white;
  padding: 16px;
}

.marginTop {
  margin-top: 8px;
}

.row {
  display: flex;
  justify-content: flex-start;
}

.marginLeft {
  margin-left: 16px;
}

.heading {
  font-size: $largeFontSize;
  margin-bottom: 8px;
  font-weight: $semiBoldFont;
}

.count {
  color: $blue2;
}

.paddingTop {
  padding-top: 16px;
}

.newTeam {
  max-width: 187px;
  width: 100%;
  margin-right: 16px;
}
.buttonStyles {
  font-size: 14px;
  color: white;
  padding: 16px;
  font-weight: 400;
}

.buttonGrey {
  background-color: $white5;
  color: $gray2;
  font-weight: 400;
  font-size: 14px;
  padding: 16px;
  max-width: 135px;
  width: 100%;
}

.divImg {
  margin-right: 8px;
}

.empty {
  display: flex;
  justify-content: center;
  margin: 150px 0;
}

.width {
  max-width: 275px;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 16px;
}

.desc {
  font-size: $commonFontSize;
  font-weight: $normalFont;
  color: $gray1;
}

.pagination {
  display: flex;
  justify-content: center;
}

.textCenter{
  text-align: center;
}