.attachment {
  display: flex;
  width: 100%;
  height: 57px;
  margin-top: 4px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 1px 1px 4px 0px #4951576b;
}
.iconWrapper {
  width: 30px;
  height: 30px;
  background-color: #f0f1f2;
  border-radius: 22px;
  margin-right: 5px;
}
.iconStyle {
  width: 24px;
  height: 30px;
  margin-left: 3px;
}
.attachmentData {
  display: flex;
  flex-direction: column;
  margin-left: 2px;
}
.attachmentName {
  font-size: 14px;
  // color: #3f4149;
  a {
    color: white !important;
  }
  max-width: 317px;
  overflow: hidden;
}
.attachmentSize {
  font-size: 10px;
  color: white;
}
