@import '../../common.scss';

.list-card {
  border: 1px solid $white4;
  padding: 16px;
  display: flex;
  background: $white;
  min-height: 187px;
  flex-direction: column;
  margin-bottom: 16px;
}

.list-card-title {
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
  line-height: 150%;
  color: $orange2;
  margin-top: 16px;
}

.list-card-content {
  font-size: $commonFontSize;
  line-height: 160%;
  color: $gray2;
  margin-bottom: 24px;
  font-weight: $normalFont;
}

.list-card-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.d-flex {
  display: flex;
}

.review-card__time-icon {
  margin-right: 5px;
}

.review-card__align-items {
  display: flex;
  align-items: center;
  height: 22px;
  margin-right: 16px;
  white-space: nowrap;
  padding: 0 8px;
  font-size: $xsmalFontSize;
  line-height: 120%;
  color: $gray2;
  font-weight: $mediumFont;
}
.zeroMinWidth {
  min-width: 0 !important;
}

.green-label {
  color: $white;
  background: $green1;
}

.red-label {
  color: $white;
  background: $red1;
}

.blue-label {
  color: $white;
  background: $blue2;
}

.review-card__profile {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  margin-top: auto;
}

.review-card__star-rating {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  margin-left: 16px;
}

.review-card__username {
  font-weight: $mediumFont;
  font-size: $smallFontSize;
  line-height: 128%;
  color: $orange2;
}

.review-card__rating {
  display: flex;
  font-size: $smallFontSize;
  align-items: center;
  color: $gray2;
}

.review-card__rating-icons {
  max-width: 8px;
  width: 100%;
  height: 8px;
}

.backgroundImg {
  background-color: $blue2;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  color: $white;
  justify-content: center;
  align-items: center;
  font-weight: $semiBoldFont;
  font-size: $mediumFontSize;
}

.icon {
  height: 40px;
  width: 40px;
  border-radius: 40px;
}

.dotted-menu {
  cursor: pointer;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.element {
  color: $gray1;
  font-size: $commonFontSize;
  display: flex;
  height: 36px;
  width: 186px;
  align-items: center;
}

.elementImage {
  margin-right: 16px;
  max-width: 20px;
  max-height: 20px;
  width: 100%;
  height: 100%;
}

.red-patch {
  background: $red2;
  color: $red3;
}
.green {
  background: $green1;
  color: $white;
  font-size: $commonFontSize;
  font-weight: normal;
}
