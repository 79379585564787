@import '../../common.scss';

.list-card {
  border: 1px solid $white4;
  padding: 16px;
  display: flex;
  background: $white;
  flex-direction: column;
}
.respondent__align-items {
  display: flex;
  align-items: center;
  height: 22px;
  font-size: $commonFontSize;
  line-height: 120%;
  margin: 8px 0;
  color: $gray2;
  font-weight: $mediumFont;
}
.respondent__align-icon {
  height: 16px;
  margin-right: 16px;
}
.review-card__profile {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  margin: 24px 0px 16px;
}
.userImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.icon {
  max-width: 40px;
  width: 100%;
  max-height: 40px;
  height: 100%;
}
.review-card__star-rating {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  margin-left: 16px;
}
.review-card__username {
  font-weight: $mediumFont;
  font-size: $smallFontSize;
  line-height: 128%;
  color: $orange2;
}
.review-card__rating {
  img {
    margin-right: 8px;
  }
  display: flex;
  align-items: center;
}
.edit-title {
  font-style: normal;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  line-height: 160%;
  color: $orange2;
}
.textarea-styles {
  width: 100%;
  margin-top: 8px;
  padding: 15px;
  line-height: 19px;
  height: 100px;
  font-size: $mediumFontSize;
  resize: none;
  border: 1px solid $gray4;
  outline-style: none;
}
.errorPosition {
  position: relative;
}
.white {
  color: $white;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
.cancel-btn {
  background-color: $gray0;
  color: $black;
  margin-top: 10px;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
.error {
  color: $red;
  margin-bottom: 10px;
  font-size: $smallFontSize;
  font-weight: $thinFont;
  position: absolute;
}
.character-count {
  font-weight: $thinFont;
  font-size: $smallFontSize;
  line-height: 120%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: right;
  color: $gray1;
}
.box-btn {
  width: 124px;
}

.btn-img {
  margin-right: 16px;
}

.btn-color {
  color: $white !important;
  width: 100% !important;
  font-size: $commonFontSize !important;
  cursor: pointer;
  height: 48px !important;
  outline: none !important;
  box-shadow: none;
  margin-top: 16px;
}
