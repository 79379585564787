@import '../../common.scss';
.form {
  background-color: $white;
  padding: 17px 16px;
}
.title {
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  margin-bottom: 8px;
}
.imgBtn {
  margin-right: 16px;
}
.button {
  max-width: 121px;
  width: 100%;
  max-height: 48px;
  height: 100%;
  margin-top: 32px;
  margin-right: 16px;
  font-size: $commonFontSize;
  color: $white;
  padding: 16px 15.5px;
  font-weight: $thinFont;
}
.resend {
  font-weight: $normalFont;
  font-size: $commonFontSize;
  margin-top: 32px;
  background-color: $white5;
  color: $gray2;
  max-width: 150px;
  width: 100%;
  max-height: 48px;
  height: 100%;
  padding: 16px 0;
}
.wrapper {
  display: flex;
  justify-content: flex-start;
}
.field {
  margin-bottom: 16px;
}
.white {
  color: $white;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
.cancel-btn {
  background-color: $gray0;
  color: $black;
  margin-top: 10px;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
.errorMsg {
  color: $red;
  position: absolute;
  font-size: $smallFontSize;
  bottom: 20%;
}
.paddingRight {
  padding-right: 36px;
}
