@import '../../common.scss';

.whiteBackground {
  background-color: $white;
}
.boldText {
  font-size: $commonFontSize;
  font-weight: $semiBoldFont;
}

.padding {
  padding: 16px;
}

.marginTop {
  margin-top: 8px;
}

.marginBtm {
  margin-bottom: 16px;
}

.divImg {
  margin-right: 8px;
}

.commonBtnWrapper {
  display: flex;
  justify-content: flex-start;
}

.white {
  max-width: 101px;
  width: 100%;
  color: $white;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
.cancel-btn {
  color: $gray2;
  background-color: $white5;
  font-weight: $normalFont;
  font-size: $commonFontSize;
  max-width: 101px;
  width: 100%;
}
.marginRight {
  margin-right: 16px;
}

.resend {
  font-weight: $normalFont;
  font-size: $commonFontSize;
  background-color: $white5;
  color: $gray2;
  max-width: 150px;
  width: 100%;
  max-height: 48px;
  height: 100%;
  padding: 16px 0;
}
