@import '../../common.scss';
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.content {
  overflow: hidden;
  background-color: $white;
  scroll-behavior: smooth;
  height: 100%;
}

.hide {
  opacity: 0.3;
  pointer-events: none;
}

.options {
  position: relative;
  padding: 16px;
  border-top: 1px solid $gray5;
  max-width: 1158px;
  width: 100%;
  background: linear-gradient(0deg, $white, $white), $white3;
}

.header {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $gray5;
  max-height: 80px;
  height: 100%;
}

// .details{
//     display: flex;
//     justify-content: center;
// }

.name {
  font-size: $largeFontSize;
  font-weight: $semiBoldFont;
  line-height: 130%;
}

.info {
  color: $gray1;
  font-size: $smallFontSize;
}

.element {
  color: $gray1;
  font-size: $commonFontSize;
  display: flex;
  height: 36px;
  width: 186px;
  align-items: center;
}

.elementImage {
  margin-right: 16px;
  max-width: 12px;
  max-height: 12px;
  width: 100%;
  height: 100%;
}

.chats {
  padding: 16px;
}

.col {
  display: flex;
  flex-direction: column-reverse;
  height: calc(100vh - 328px);
  overflow: auto;
}

.textbox {
  font-weight: $normalFont;
  font-size: $commonFontSize;
  line-height: 19px;
  background: $white;
  color: $gray2;
  // background-image: url(../../assets/images/svg/send.svg);
  background-repeat: no-repeat;
  background-position: 98%;
  height: 48px;
  border: 1px solid $gray4;
  outline: none;
  padding: 14px 16px;
  padding-right: 0 !important;
  border-right: none;
}
.fullwidth {
  width: 630px;
}
.teachersWidth {
  width: 575px;
}
.doc {
  cursor: pointer;
  margin-right: 16px;
}

.menu {
  display: flex;
  margin-right: 16px;
  margin-left: 16px;
  cursor: pointer;
  div {
    display: flex;
  }
}

.buttonClass {
  max-width: 180px;
  min-width: 100px;
  width: 100%;
  max-height: 48px;
  height: 100%;
  color: $white;
  font-weight: normal;
  padding: 15.5px 16px;
  font-size: $commonFontSize;
}

.d-flex {
  display: flex;
}

.btnImg {
  margin-right: 5px;
}

.upload {
  max-width: 500px;
  width: 100%;
  background-color: $white;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  float: right;
  padding: 16px;
  margin-right: 20px;
}

.background {
  background-color: $white;
}

.fileIcon {
  margin: 5px;
}

.send {
  padding: 14px 16px;
  border: 1px solid $gray4;
  border-left: none;
  height: 48px;
  cursor: pointer;
}
.blocked {
  color: $red;
}

.error {
  position: absolute;
  font-size: 12px;
  color: red;
  margin-left: 16px;
}

.proposal {
  cursor: pointer;
  background-color: #4b4bbc;
  color: #fff;
  display: flex;
  justify-content: center;
  width: 48px;
  height: 48px;
  align-items: center;
  margin-right: 16px;
}

.fileName {
  position: absolute;
  left: 135px;
  top: -40px;
  padding: 0 8px;
  border-radius: 12px;
  background-color: #4b4bbccc;
  color: white;
  display: flex;
  align-items: center;
}

.cancelUpload {
  cursor: pointer;
}

.channel {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 60px;
}

.iconUrl {
  border-radius: 50%;
  max-width: 140px;
  width: 100%;
  min-height: 140px;
  height: 100%;
}

.flex {
  display: flex;
  justify-content: center;
}

.team-wrapper {
  .col {
    height: 270px !important;
  }
  .content {
    height: 250px !important;
  }
  .options {
    padding: 16px 0 0;
    border: none;
    border-top: 1px solid #e5e5e5;
  }
  .fullwidth {
    width: 600px;
  }
}

.file {
  margin-left: 65px;
  max-width: 200px;
  width: 100%;
}

.mentorFile {
  margin-left: 120px;
  max-width: 200px;
  width: 100%;
}

.fileTeams {
  margin-left: 65px;
  max-width: 200px;
  width: 100%;
}

.member {
  font-size: $smallFontSize;
  color: $gray1;
}

.loader {
  width: 100%;
  height: 100%;
}
