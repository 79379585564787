@import '../../common.scss';

.element {
  background-color: $white;
  padding: 16px 0;
}

.title {
  display: flex;
  justify-content: flex-start;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  color: $gray2;
  margin-top: 8px;
  margin-bottom: 24px;
}

.padding {
  padding: 0 16px;
}
.divImg {
  margin-right: 16px;
}

.elementBtm {
  margin-bottom: 16px;
}

.button {
  color: $white;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}

.buttonWrapper {
  max-width: 234px;
  width: 100%;
  padding-top: 16px;
  margin-left: 16px;
}

.field_label {
  font-weight: $mediumFont;
  color: $orange2;
  font-size: $commonFontSize;
}

.field_label_text {
  font-weight: $normalFont;
  color: $gray2;
  font-size: $smallFontSize;
  margin-bottom: 8px;
}

.field_wrapper {
  margin-bottom: 16px;
}

.dflex {
  display: flex;
  justify-content: space-between;
}

.dflexStart {
  display: flex;
  justify-content: flex-start;
}

.icon {
  min-width: 40px;
  width: 100%;
}

.width {
  min-width: 500px;
}

.delete {
  background-color: $gray4;
  padding: 14px;
  max-width: 40px;
  width: 100%;
  height: 100%;
}

.deleteIcon {
  max-width: 12px;
  width: 100%;
  height: 100%;
}

.userName {
  font-weight: $mediumFont;
  font-size: $commonFontSize;
}

.landing-page__search {
  width: 100%;
  cursor: pointer;
  position: relative;
}
.backgroundImg {
  background-image: url(../../assets/images/svg/blue-dropdown.svg);
}
.list {
  margin: 0;
  padding: 0;
  max-height: 147px;
  background: $white;
  overflow: auto;
  width: 100%;

  li {
    height: 48px;
    font-size: $commonFontSize;
    line-height: 120%;
    color: $gray2;
    display: flex;
    margin: 0 16px;
    align-items: center;
    list-style-type: none;
    border-bottom: 1px solid $gray3;
    cursor: pointer;

    div {
      margin-left: 10px;
    }
  }
}
.members {
  max-width: 729px;
  width: 100%;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $gray5;
}

.membersList-userDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 32px;
  margin-left: 16px;
}

.membersList-username {
  font-weight: $mediumFont;
  font-size: $smallFontSize;
  line-height: 128%;
  color: $orange2;
}

.membersList-role {
  font-weight: $normalFont;
  font-size: $smallFontSize;
  line-height: 128%;
  color: $orange2;
}

.membersList-profile {
  width: 100%;
  max-width: 180px;
  height: 40px;
  display: flex;
  align-items: center;
}

.userImg {
  height: 40px;
  width: 40px;
  justify-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.delete_img {
  margin-left: 16px;
  cursor: pointer;
}

.red {
  color: $white;
  background: $red1;
  font-size: $commonFontSize;
  font-weight: normal;
}
.mTop {
  margin-top: 140px;
}
.errorMsg {
  font-size: $smallFontSize;
  font-weight: $normalFont;
  color: $red1;
  padding-left: 20px;
  position: relative;
  top: -15px;
}
.white {
  color: $white;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
