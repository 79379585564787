@import '../../common.scss';
.box {
  background-color: $white;
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;
}
.input {
  width: 100%;
  display: flex;
  justify-content: space-between;
  :first-child {
    margin-right: 10px;
  }
}
.margins {
  margin-bottom: 16px;
}
.cancel-btn {
  background-color: $gray0;
  color: $black;
  margin-top: 10px;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
.white {
  color: $white;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
.block {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50%;
}

.label {
  margin-bottom: 10px;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  line-height: 160%;
}

.input-styles {
  width: 100%;
  padding: 5px 15px;
  line-height: 19px;
  height: 48px;
  margin-bottom: 16px;
  font-size: $mediumFontSize;
  border: 1px solid $gray4;
  outline-style: none;
  box-shadow: none;
}
.ph {
  margin-bottom: 16px;
  font-weight: $mediumFont;
  height: 48px;
  font-size: $commonFontSize;
  line-height: 160%;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.textarea-styles {
  width: 100%;
  margin-bottom: 32px;
  padding: 15px;
  line-height: 19px;
  height: 148px;
  margin-bottom: 32px;
  font-size: $mediumFontSize;
  resize: none;
  border: 1px solid $gray4;
  outline-style: none;
}

.box-btn {
  width: 60%;
}

.btn-img {
  margin-right: 12px;
}
.btn-color {
  color: $white;
  font-weight: normal;
  max-width: 180px;
  width: 100%;
  font-size: $commonFontSize;
  height: 48px;

  outline: none !important;
  box-shadow: none;
}
.error {
  color: $red;
  margin-bottom: 10px;
  font-size: $smallFontSize;
  font-weight: $thinFont;
}
