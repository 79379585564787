@import '../../common.scss';

.wrapper {
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 0 60px 0;
  background: linear-gradient(0deg, $gray0, $gray0), $white;
  opacity: 0.98;
}

.tabHeaders {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  background-color: $blue3;
  color: $white;
  top: 0px;
  z-index: 999;
  border-bottom: 1px solid $gray7;
  border-top: 1px solid $gray7;
}

.tab_container {
  max-width: 1084px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
