@import '../../common.scss';

.datePicker {
  margin-top: 8px !important;
  border: 1px solid $gray4 !important;
  height: 48px;
  display: flex !important;
  justify-content: center;
  padding: 16px !important;
}
