@import '../../common.scss';
.accordionSection {
  max-width: 1158px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.accordionWrapper {
  width: 100%;
}
.accordionDetails {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
}
.accordion {
  background: linear-gradient(0deg, $white3, $white3), $white;
  box-shadow: none !important;
  margin-bottom: 16px;
}
.accordionChild {
  background: linear-gradient(0deg, $white3, $white3), $white;
  border-top: 1px solid RGBA(150, 153, 156, 0.1);
  box-shadow: none !important;
}
.accordionHeaderSection {
  font-size: $mediumFontSize;
  color: $orange2;
  align-items: center;
  font-style: normal;
  display: flex;
  font-weight: $semiBoldFont;
  line-height: 150%;
  margin-left: 16px;
  & span {
    padding: 0px 10px 0 0;
  }
}
.headerIcon {
  width: 40px;
  height: 40px;
  padding: 0px 10px 0 0;
}
.accordionTitle {
  line-height: 150%;
  color: $orange2;
  width: 100%;
  padding-left: 32px;
  max-width: 538px;
  font-style: normal;
  font-weight: $semiBoldFont;
  font-size: $headerFontSize;
  margin-bottom: 40px;
}
.dashboardAccordion {
  color: $orange2;
  max-width: 520px;
  width: 100%;
  padding-left: 32px;
  font-weight: $semiBoldFont;
  font-size: $xsmallHeaderFontSize;
  margin: 40px 0;
}
.sessionSection {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 1159px;
  background-color: $white;
}

.blue {
  color: $blue2;
}

.cancel-btn {
  background-color: $gray0;
  color: $black;
  margin-top: 10px;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
.white {
  color: $white;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
.dashboardBackground {
  background: $white !important;
}
.emptyMessage {
  padding: 16px;
  font-size: $xlargeFontSize;
  font-weight: $mediumFont;
  width: 100%;
  max-width: 1153px;
  background-color: $white;
  text-align: center;
}
.radio-wrapper {
  margin-top: 22px;
}

.radio_label {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid $gray3;
  color: $black;
  font-size: $mediumFontSize;
  font-weight: $semiBoldFont;
  min-height: 57px;
  padding-bottom: 16px;
  padding: 0 16px;
}

.duration {
  color: $gray2;
  font-size: $commonFontSize;
  font-weight: $mediumFont;
  margin-bottom: 16px;
}
