@import './common.scss';

* {
  font-family: 'Poppins';
  box-sizing: border-box;
}

a {
  text-decoration: none;
  &:active {
    color: inherit;
  }
}
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  #root {
    min-height: 100vh;
  }
}

a {
  cursor: pointer;
  text-decoration: none;
}
