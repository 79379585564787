@import '../../common.scss';

.container {
  border-radius: 50%;
  background: $blue2;
  color: $white;
  height: 100%;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
