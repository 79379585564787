$black: #000;

$white: #fff;
$white1: rgba(255, 255, 255, 0.3);
$white2: #fafaf8;
$white3: #f9fafb;
$white4: #f0f1f3;
$white5: #f8f9fa;
$white6: #eee;

$green1: #41dd8c;
$green2: #73e5aeb3;

$orange1: #2e2d2c;
$orange2: #2b2a29;

$pink1: #e54dc0;

$blue1: #1d1d72;
$blue2: #4b4bbc;
$blue3: #191970;
$blue4: #393988;
$blue5: #2b2b77;

$gray0: #f1f2f4;
$gray1: #7c7c7c;
$gray2: #96999c;
$gray3: #f3f3f3;
$gray4: #dfdfdf;
$gray5: #e5e5e5;
$gray6: #f1f2f4;
$gray7: rgba(124, 124, 124, 0.3);

$blue: #4b4bbc;

$red: #ff0000;
$red1: #ff4b55;
$red2: #fee9ea;
$red3: #fe4e57;

$xsmalFontSize: 10px;
$tabCountFontSize: 11px;
$smallFontSize: 12px;
$commonFontSize: 14px;
$mediumFontSize: 16px;
$largeFontSize: 20px;
$xlargeFontSize: 24px;
$mediumLargeFontSize: 26px;
$xxlargeFontSize: 28px;
$xxxlargeFontSize: 30px;
$xsmallHeaderFontSize: 32px;
$headerFontSize: 40px;
$smallHeaderFontSize: 156px;
$mediumHeaderFontSize: 160px;
$largeHeaderFontSize: 180px;

$thinFont: 300;
$normalFont: 400;
$mediumFont: 500;
$semiBoldFont: 600;
$boldFont: 700;

input {
  &::-webkit-contacts-auto-fill-button,
  &::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }
}

.tab-wrapper {
  .MuiTabs-indicator {
    background-color: $blue2 !important;
    height: 3px !important;
  }

  .MuiTab-root {
    @media (min-width: 600px) {
      min-width: unset !important;
    }
    text-transform: unset !important;
    padding: 21px 0 !important;
    &:not(:last-child) {
      margin-right: 32px;
    }
  }

  .MuiTabs-root {
    // background-color: $blue3 !important;
    min-height: unset !important;
    width: 100% !important;
  }

  .MuiTouchRipple-root {
    display: none;
  }

  .MuiTab-wrapper {
    color: $white !important;
    font-size: $mediumFontSize !important;
  }

  .MuiTabs-flexContainer {
    max-height: 78px;
  }

  .MuiTabs-flexContainer,
  .tabPanel {
    width: 100%;
    max-width: 1158px;
  }

  .MuiTabs-fixed {
    display: flex !important;
    justify-content: center !important;
  }
}

.autocomplete-wrapper {
  .MuiInputBase-root {
    border-radius: 0px;
    min-height: 48px;
    height: 100%;
    font-size: $commonFontSize;
  }
  .MuiFormControl-root {
    :focus-visible :hover {
      outline: none;
    }
  }
  .MuiInputBase-input {
    font-size: $commonFontSize;
  }
  .MuiFormControl-fullWidth {
    :focus-visible :hover {
      outline: none;
    }
  }
}
.accordion-wrapper {
  .MuiAccordion-root:before {
    display: none !important;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    .MuiAccordionSummary-content {
      opacity: 0.5;
    }
  }

  .MuiAccordionSummary-expandIcon.Mui-expanded {
    background: $white5;
    border-radius: 0;
  }
}

select {
  -moz-appearance: none;
  /* for Chrome */
  // -webkit-appearance: none;
  &::-ms-expand {
    display: none;
  }
}

.review-swiper {
  width: 100%;
  display: flex;
  justify-content: center;

  .swiper-slide {
    display: flex;
    justify-content: center;
  }

  .swiper-slide:nth-child(even) {
    margin-top: 15px !important;
  }
}

.dotted-carousel {
  width: 100%;
  height: 100%;

  .swiper-slide {
    width: 100% !important;
    padding: 0 90px 0 100px;
    display: flex;
    justify-content: space-between;
  }

  .swiper-pagination {
    text-align: left !important;
    bottom: 40px !important;
    left: 100px !important;
  }

  .swiper-pagination-bullet {
    width: 16px !important;
    height: 16px !important;
    background: $white !important;
  }
}

.show-loader {
  overflow: hidden;
  opacity: 0.8;
  padding-right: 17px;
}

.backdrop-section {
  .MuiBackdrop-root {
    z-index: 99999 !important;
  }
}

.popup-alert {
  .MuiDialogContent-root {
    padding: 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: initial;
  }
}

.autocomplete-wrapper {
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid $gray4 !important;
  }

  .MuiAutocomplete-clearIndicatorDirty {
    display: none;
  }

  .MuiIconButton-root,
  .MuiSvgIcon-root,
  .MuiIconButton-label {
    margin-right: 0px !important;
  }

  .MuiSvgIcon-root {
    fill: $blue2 !important;
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 5px 9px 9px;
  }
}

.phone {
  .MuiAutocomplete-root {
    width: 150px !important;
  }
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding-right: 35px !important;
  }
}
.search {
  .MuiChip-root {
    display: none;
  }
  .MuiAutocomplete-popupIndicatorOpen {
    transform: none !important;
  }
}

.location {
  color: $white;

  .MuiSvgIcon-root {
    fill: $white !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-top: none !important;
    border-left: none !important;
    border-bottom: 1px solid $white !important;
    border-right: none !important;
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'],
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child {
    padding-left: 0px !important;
    color: $white;
  }
}
.dashboard {
  color: $white;

  .MuiSvgIcon-root {
    fill: $white !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-top: none !important;
    border-left: none !important;
    border-bottom: 1px solid $gray7 !important;
    border-right: none !important;
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'],
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child {
    padding-left: 8px !important;
    color: $white;
    opacity: 1;
  }
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.slider_with_focus {
  .swiper-slide {
    opacity: 0.9;
  }

  .swiper-slide-active {
    opacity: 1;
  }
}
.menu-wrapper {
  position: absolute;
  .MuiMenuItem-root {
    max-height: 48px;
    height: 100%;
    max-width: 186px;
    width: 100%;
  }
  .MuiMenu-paper {
    left: 1360px !important;
  }
}

.MuiAutocomplete-option {
  border-bottom: 1px solid $gray3;
  height: 48px !important;
  width: 100% !important;
  line-height: 120%;
}

.MuiAutocomplete-paper {
  border-radius: 0 !important;
}

.error {
  color: $red;
  margin-bottom: 10px;
  font-size: $smallFontSize;
  font-weight: $thinFont;
  position: absolute;
  top: 0%;
  left: 0%;
}

.radio-rights {
  .green_radio_buttons {
    .MuiFormControlLabel-root {
      padding: 8px 5px;
      margin: 0px;
      border-bottom: 1px solid $gray5;
    }
  }
}

.green_radio_buttons {
  .Mui-checked {
    color: $green1 !important;
    & + .MuiFormControlLabel-label {
      color: $orange2;
    }
  }
  .MuiIconButton-colorSecondary:hover {
    background-color: $white !important;
  }
}

.autocomplete_disable_icon_animation {
  .MuiAutocomplete-popupIndicatorOpen {
    transform: none !important;
  }
}

.autocomplete-custom-tag {
  border-radius: 0px !important;
  padding: 10px 16px !important;
  background: $blue2 !important;
  color: $white !important;
  .MuiChip-label {
    padding: 0 !important;
    margin-right: 10px !important;
  }
  .MuiChip-deleteIcon {
    fill: $white !important;
  }
}
.form_loader {
  margin-right: 10px;
}

.date-picker {
  .MuiInput-underline:after,
  .MuiInput-underline:before {
    border: none !important;
  }
}

.MuiPickersCalendarHeader-iconButton {
  .MuiIconButton-label {
    .MuiSvgIcon-root {
      color: $blue !important;
    }
  }
}

.MuiPickersCalendarHeader-transitionContainer {
  left: -70px;
}

.MuiPickersCalendarHeader-iconButton:first-child {
  left: 70%;
}

.checkbox_groups {
  .MuiFormGroup-root {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
  }

  .MuiFormControlLabel-label {
    border: 1px solid $gray4 !important;
    font-size: $commonFontSize !important;
    color: $gray2;
    padding: 11px 14px !important;
  }

  .MuiIconButton-label {
    display: none !important;
  }

  .PrivateSwitchBase-root-9 {
    padding: 0 !important;
  }

  .MuiFormControlLabel-root {
    margin-left: 0 !important;
  }

  .Mui-checked {
    & + .MuiFormControlLabel-label {
      color: $white !important;
      background: $blue2;
    }
  }
}

input[type='time'] {
  cursor: text;
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
}
.menu-list-wrapper {
  .MuiMenu-list {
    .MuiListItem-gutters {
      color: $gray1;
    }

    .Mui-selected {
      color: $black;
    }
  }

  .MuiMenuItem-root {
    white-space: inherit !important;
  }
}
.MuiFormHelperText-root.Mui-error {
  position: absolute;
  top: 100%;
  margin-left: -17px;
}

.cardElement {
  .StripeElement {
    margin-top: 10px;
    padding: 15px 10px;
    height: 48px;
    border: 1px solid $gray4;
  }
}
.MuiFormGroup-root {
  width: 100%;
}

.MuiDrawer-paper {
  flex: none !important;
  overflow-x: hidden !important;
}
