@import '../../common.scss';

.list-card {
  border: 1px solid $white4;
  padding: 16px;
  display: flex;
  background: $white;
  flex-direction: column;
}

.list-card-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.list-card-title {
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
  line-height: 150%;
  color: $orange2;
  margin-top: 16px;
}

.list-card-content {
  font-size: $commonFontSize;
  line-height: 160%;
  color: $gray2;
}

.list-card-description {
  font-size: $commonFontSize;
  line-height: 160%;
  color: $gray1;
}

.list-card-footer {
  margin-top: 24px;
  display: flex;
}

.d-flex {
  display: flex;
}

.respondent__align-items {
  display: flex;
  align-items: center;
  height: 22px;
  font-size: $commonFontSize;
  line-height: 120%;
  margin: 8px 0;
  color: $gray2;
  font-weight: $mediumFont;
}

.respondent__align-icon {
  height: 16px;
  margin-right: 16px;
}

.btn-cancel {
  color: $gray2;
  font-weight: normal;
  max-width: 150px !important;
  background: $white5 !important;
  width: 100%;
  font-size: $commonFontSize;
  height: 48px;
  margin-right: 16px;
}

.btn-img {
  margin-right: 12px !important;
}

.request-section {
  max-width: 1158px;
  width: 100%;
  height: 158px;
  margin: 16px 0;
  padding: 0 16px;
  background: $white;
  display: flex;
  flex-direction: column;
}

.list-title {
  height: 78px;
  display: flex;
  align-items: center;
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
  line-height: 150%;
  color: $orange2;
}

.color-blue {
  color: $blue2;
}

.list-filter {
  display: flex;
  align-items: center;
  padding: 8px 7px;
  max-width: 127px;
  width: 100%;
  height: 48px;
  margin: 16px 0;
  font-size: $commonFontSize;
  color: $gray2;
  background: $white5;
  cursor: pointer;

  img {
    margin-right: 10px;
  }
}

.review-card__profile {
  width: 170px;
  height: 40px;
  display: flex;
  cursor: pointer;
  align-items: center;
  margin: 24px 0px 16px;
}

.review-card__star-rating {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 32px;
  margin-left: 16px;
}

.review-card__username {
  font-weight: $mediumFont;
  font-size: $smallFontSize;
  line-height: 128%;
  color: $orange2;
}

.review-card__rating {
  img {
    margin-right: 8px;
  }
  display: flex;
  align-items: center;
}

.backgroundImg {
  background-color: $blue2;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  color: $white;
  justify-content: center;
  align-items: center;
  font-weight: $semiBoldFont;
  font-size: $mediumFontSize;
}

.icon {
  max-width: 40px;
  width: 100%;
  max-height: 40px;
  height: 100%;
}

.connect {
  padding-top: 16px;
}

.btn-connect {
  width: 120px;
  color: $white;
  background: $blue;
  height: 48px;
  font-size: $commonFontSize;
  padding: 8px;
}

.btnWrapper {
  display: flex;
  justify-content: start;
}

.connect-img {
  margin-right: 8px;
}

.edit-title {
  font-style: normal;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  line-height: 160%;
  color: $orange2;
}

.textarea-styles {
  width: 100%;
  margin-top: 8px;
  padding: 15px;
  line-height: 19px;
  height: 100px;
  font-size: $mediumFontSize;
  resize: none;
  border: 1px solid $gray4;
  outline-style: none;
}

.error {
  color: $red;
  margin-bottom: 10px;
  font-size: $smallFontSize;
  font-weight: $thinFont;
  position: absolute;
}

.character-count {
  font-weight: $thinFont;
  font-size: $smallFontSize;
  line-height: 120%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: right;
  color: $gray1;
}

.box-btn {
  width: 124px;
}

.btn-img {
  margin-right: 16px;
}

.btn-color {
  color: $white !important;
  width: 100% !important;
  font-size: $commonFontSize !important;
  cursor: pointer;
  height: 48px !important;
  outline: none !important;
  box-shadow: none;
  margin-top: 16px;
}

.review-card__align-items {
  display: flex;
  align-items: center;
  margin-right: 28px;
  white-space: nowrap;
  height: 22px;
  font-size: $xsmalFontSize;
  line-height: 120%;
  background: $white5;
  color: $gray2;
  font-weight: $mediumFont;
}

.review-card__time-icon {
  height: 12px;
  margin-right: 5px;
}

.userImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.errorPosition {
  position: relative;
}
.white {
  color: $white;
}
.cancel-btn {
  background-color: $gray0;
  color: $black;
  margin-top: 10px;
}

.btn_connected {
  background: transparent;
  max-width: 143px;
  border: 1px solid $gray4;
  font-size: $commonFontSize;
  font-weight: $normalFont;
  cursor: auto;
  color: $gray2;
  padding: 16px;
}
.btn_responded {
  background: $gray3;
  max-width: 163px;
  margin-left: 16px;
  font-size: $commonFontSize;
  font-weight: $normalFont;
  cursor: pointer;
  padding: 16px;
  color: $gray2;
}
