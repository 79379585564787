@import '../../common.scss';

.tabItem {
  opacity: 0.3;
}
.count {
  color: $white;
  border-radius: 16px;
  background-color: $blue2;
  opacity: 1;
  width: 20px;
  height: 20px;
  margin-left: 8px;
  font-size: $tabCountFontSize;
  padding: 4px 9px;
}
.flex {
  display: flex;
  justify-content: center;
}
