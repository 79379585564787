@import '../../common.scss';

.label {
  color: $gray2;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  display: flex;
  justify-content: left;
  padding: 22px 0;
}
.divImg {
  margin-right: 16px;
}
.heading {
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  margin-bottom: 8px;
}
.heading2 {
  font-weight: $mediumFont;
  font-size: $commonFontSize;
}
.headingCheckBox {
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  margin-top: 10px;
}
.wrapperLeft {
  display: flex;
  justify-content: left;
  margin-left: 16px;
}
.fullField {
  width: 100%;
  margin-bottom: 16px;
}
.halfField {
  max-width: 248px;
  width: 100%;
  margin-bottom: 16px;
}
.buttons {
  max-width: 121px;
  width: 100%;
  max-height: 48px;
  height: 100%;
  margin: 16px 16px 0 16px;
  color: $white;
  padding: 15.5px 16px;

  font-size: $commonFontSize;
}
.cancel {
  max-width: 121px;
  width: 100%;
  max-height: 48px;
  color: $gray2;
  height: 100%;
  background-color: $white;
  margin: 16px 16px 0 16px;
  padding: 15.5px 16px;
  font-size: $commonFontSize;
}
.error {
  font-size: $smallFontSize;
  color: $red;
  font-weight: $thinFont;
  position: absolute;
  top: 100%;
}
.marginBtm {
  margin-bottom: 16px;
  background-color: $white;
  padding: 16px;
  padding-top: 0 !important;
}
.textareaStyles {
  width: 100%;
  padding: 15px;
  line-height: 19px;
  height: 148px;
  font-size: $mediumFontSize;
  resize: none;
  border: 1px solid $gray4;
  outline-style: none;
}
.subHeading {
  font-size: $smallFontSize;
  color: $gray2;
  margin-bottom: 8px;
}
.smallField {
  max-width: 45px;
}
.cost {
  border-bottom: 1px solid $gray4;
}
.topborder {
  display: flex;
  justify-content: space-between;
  padding-right: 151px;
  border-top: 1px solid $gray4;
  padding: 16px 0;
}

.border {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $gray4;
  padding: 16px 151px 16px 0;
}
.autocompletePosition {
  position: relative;
  margin-bottom: 16px;
}

.dolar {
  font-weight: $boldFont;
}
.optionWrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
}
.color {
  color: $blue2;
}
.btnWrapper {
  display: flex;
  justify-content: flex-start;
  background-color: $white;
  padding-bottom: 16px;
}
.switchWrapper {
  display: flex;
  justify-content: flex-start;
  background-color: white;
  padding-left: 16px;
}

.cancel-btn {
  background-color: $gray0;
  color: $black;
  margin-top: 10px;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
.white {
  color: $white;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
.costPadding {
  background-color: $white;
  padding-bottom: 16px;
}
.labelCost {
  color: $gray2;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  display: flex;
  justify-content: left;
  padding: 22px 0;
  margin-left: 16px;
}
.heading2Cost {
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  margin-left: 16px;
}
.subHeadingCost {
  font-size: $smallFontSize;
  color: $gray2;
  margin-bottom: 8px;
  margin-left: 16px;
}
.interestWrapper {
  padding-top: 22px;
}

.radio_label {
  border-bottom: 1px solid $gray3;
  color: $gray2;
  min-height: 57px;
  padding-bottom: 16px;
}
