@import '../../common.scss';

.autocomplete-dropdown {
  font-size: $commonFontSize;
  color: $gray2;
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    margin: 0 16px 0 0;
  }
}
