@import '../../common.scss';

.submit-form {
  width: 523px;
  margin: 60px 111px;
}

.submit-text {
  width: 100%;
  font-style: normal;
  font-weight: $mediumFont;
  background: $blue2;
  line-height: 19px;
  height: 48px;
  font-size: $mediumFontSize;
  color: $white;
  border-bottom: 1px solid $white !important;
  box-shadow: none;
  border: none;
  outline: none;
  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
}

.form-field2 {
  position: relative;
}
.dashboard {
  max-width: 1084px;
  width: 100%;
}

.list {
  margin: 0;
  padding: 0;
  max-height: 147px;
  position: absolute;
  background: $white;
  overflow: auto;
  max-width: 523px;
  top: 48px;
  width: 100%;

  li {
    height: 48px;
    font-size: $commonFontSize;
    line-height: 120%;
    color: $gray2;
    display: flex;
    align-items: center;
    list-style-type: none;
    border-bottom: 1px solid $gray3;
    cursor: pointer;

    img {
      margin: 0 16px;
    }
  }
}

.apply-btn {
  width: 100%;
  margin-top: 16px;
}

.btn-img {
  margin-right: 16px;
}
.autocompletePosition {
  margin-bottom: 32px;
  position: relative;
}
.outer {
  margin-bottom: 32px;
}
.error {
  color: $red;
  font-size: $smallFontSize;
  position: absolute;
  top: 100%;
  font-weight: $thinFont;
}
.cancel-btn {
  background-color: $gray0;
  color: $black;
  margin-top: 10px;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
.white {
  color: $white;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
