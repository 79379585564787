@import '../../common.scss';

.common-btn-style {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  width: 100%;
  height: 48px;
  background: $green1;
  border: none;
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
  line-height: 120%;
  cursor: pointer;
  color: $blue3;
}
