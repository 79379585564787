@import '../../common.scss';

.review-card {
  display: flex;
  flex-direction: column;
  height: 210px;
  // max-width: 365px;
  border: 1px solid $white4;
  width: 100%;
  background: $white;
  padding: 0 16px;
  margin: 0 8px;
}

.review-card:nth-child(even) {
  margin-top: 15px;
}

.review-card__time {
  width: 100%;
  height: 54px;
  font-weight: $mediumFont;
  font-size: $xsmalFontSize;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: $gray2;
}

.review-card__rating-icons {
  max-width: 8px;
  width: 100%;
  height: 8px;
}

.review-card__time-icon {
  height: 12px;
  margin: 10px 5px;
}
.review-card__time-subject {
  margin: 10px 5px;
}

.review-card__subject {
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
  line-height: 150%;
  color: $orange2;
}

.review-card__review {
  font-size: $commonFontSize;
  line-height: 160%;

  max-width: 333px;
  width: 100%;
  color: $gray2;
}

.review-card__profile {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  margin: auto 0px 16px;
}

.review-card__star-rating {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  margin-left: 16px;
}

.review-card__username-img {
  max-width: 40px;
  width: 100%;
  height: 40px;
}

.review-card__username {
  font-weight: $mediumFont;
  font-size: $smallFontSize;
  line-height: 128%;
  color: $orange2;
}

.review-card__rating {
  img {
    margin-right: 8px;
  }
  display: flex;
  align-items: center;
}
.review-card__align-items {
  background: linear-gradient(0deg, $white2, $white2);
  display: flex;
}
