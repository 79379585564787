@import '../../common.scss';
.heading {
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
  line-height: 150%;
  text-align: center;
  margin-bottom: 16px;
}
.innerBox {
  max-width: 274px;
  width: 100%;
}
.subHeading {
  font-size: $commonFontSize;
  color: $gray1;
  text-align: center;
}
.link {
  text-align: center;
  font-size: $commonFontSize;
  margin-bottom: 32px;
  color: $blue;
}
.formHeading {
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  margin-bottom: 8px;
}
.field {
  margin-bottom: 16px;
}
.btn {
  color: $white;
  margin: 32px 0 6px;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
.forgotPass {
  color: $gray1;
  background-color: $gray3;
  font-size: $commonFontSize;
  font-weight: $normalFont;
  text-align: center;
  padding-top: 16px;
}
.loginForm {
  max-width: 274px;
  width: 100%;
}
.pass {
  cursor: pointer;
}
.white {
  color: $white;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
.cancel-btn {
  background-color: $gray0;
  color: $black;
  margin-top: 10px;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
.linkForgotPass {
  text-align: center;
  font-size: $commonFontSize;
  margin-bottom: 32px;
  color: $gray1;
}
.email_verification_image {
  max-width: 274px;
  width: 100%;
}
.paddingRight {
  padding-right: 36px;
}

.form_loader {
  margin-right: 10px;
}
