@import '../../common.scss';

.empty_container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .empty_text {
    font-size: $largeFontSize;
    font-weight: $semiBoldFont;
    color: $orange2;
  }
  .sub_text {
    font-size: $commonFontSize;
    font-weight: $normalFont;
    color: $gray1;
  }
}
.marginTop {
  margin-top: 20px;
}
