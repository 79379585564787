.header {
  display: flex;
  justify-content: center;
  margin: 60px auto;
}
.boxWrapper {
  display: flex;
  flex-direction: column;
  max-width: 404px;
  width: 100%;
}
