@import '../../common.scss';

.notificationWrapper {
  width: 396px;
  height: 100%;
}

.notificationHeader {
  height: 72px;
  background: $blue3;
  padding: 16px;
}

img {
  cursor: pointer;
}

.notificationListCard {
  border-bottom: 1px solid $gray3;
  display: flex;
  padding: 16px;
  min-height: 72px;
}

.read {
  background-color: $gray0;
}

.icon {
  height: 40px !important;
  width: 40px;
  border-radius: 50%;
}

.notificationContent {
  width: 243px;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  line-height: 160%;
  font-size: $smallFontSize;
}

.notificationTitle {
  font-weight: $mediumFont;
  color: $orange2;
}

.notificationDescription {
  color: $gray1;
}

.notificationDate {
  line-height: 160%;
  font-size: $xsmalFontSize;
  color: $gray1;
}
.marginTop {
  margin-top: 150px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
}

.addCursor{
  cursor: pointer;
}
