@import '../../common.scss';

.wrapper {
  display: flex;
  justify-content: center;
}
.fullWidth {
  max-width: 1158px;
  width: 100%;
}
.background {
  background-color: $white;
}
.left {
  max-width: 381px;
  width: 100%;
  height: 100%;
  // padding: 16px;
  border: 1px solid $gray5;
  position: relative;
  border-top: 0 !important;
}
.right {
  width: 777px;
}

.grayBackground {
  background-color: $gray5;
  display: flex;
  justify-content: center;
  height: calc(100vh - 145px);
  padding-top: 16px;
}

.padding {
  padding: 0 16px;
  background-color: $white;
}

.chat {
  height: 72px;
  display: flex;
  justify-content: space-between;
  margin: 0 16px 16px;
}
.active {
  background-color: $gray6;
  pointer-events: none;
  height: 72px;
  display: flex;
  justify-content: space-between;
  margin: 0 16px 16px;
}
.name {
  padding: 16px 0;
  max-width: 233px;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 16px 0 16px 16px;
  position: relative;
}

.icon {
  width: 40px;
  height: 40px;
  margin-right: 16px;
  border-radius: 44px;
}

.radius {
  border-radius: 40px;
  max-width: 40px;
  max-height: 40px;
  width: 100%;
  height: 100%;
}

.width {
  max-height: 60px;
  width: 100%;
  height: 100%;
}

.fullName {
  font-size: $smallFontSize;
  color: $black;
  width: 100%;
  font-weight: $mediumFont;
}

.count {
  width: 20px;
  height: 20px;
  border-radius: 16px;
  background-color: $blue2;
  color: $white;
  align-items: center;
  font-weight: 600;
  padding-left: 7px;
  padding-top: 3px;
  font-size: 11px;
}

.chatWrapper {
  padding-top: 25px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.chatText {
  font-size: $smallFontSize;
  color: $black;
  width: 100%;
  font-weight: $normalFont;
  color: $gray1;
}

.img {
  position: absolute;
  top: 60%;
  left: 66%;
}

.channelLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.content {
  overflow-y: auto;
  height: calc(100vh - 241px);
  scroll-behavior: smooth;
}

.tabHeaders {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  background-color: $blue3;
  color: $white;
  top: 0px;
  z-index: 999;
  border-bottom: 1px solid $gray7;
  border-top: 1px solid $gray7;
}

.container {
  max-width: 1084px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
