@import '../../common.scss';

.wrapperClass {
  background-color: $white;
  width: 100%;
  height: 100%;
}
.heading {
  padding: 24px 16px;
  display: flex;
  justify-content: flex-start;
  color: $gray2;
  font-weight: $normalFont;
  font-size: $commonFontSize;
}
.divImg {
  margin-right: 16px;
}
.boldFont {
  padding: 0 16px;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  color: $black;
}
.thinFont {
  font-weight: $normalFont;
  font-size: $smallFontSize;
  padding: 0 16px;
  color: $gray1;
  margin-bottom: 16px;
}
.user {
  display: flex;
  justify-content: flex-start;
  padding: 0 16px;
  margin-bottom: 24px;
}
.info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.ratings {
  border-top: 1px solid $gray4;
  display: flex;
  justify-content: space-between;
  padding: 16px;
}
.topic {
  font-size: $normalFont;
  color: $gray1;
  padding: 4px 0 5px 8px;
}
.marginTop {
  margin-top: 16px;
}
.textareaStyles {
  width: 95%;
  padding: 15px;
  margin-left: 16px;
  margin-right: 16px;
  line-height: 19px;
  height: 148px;
  font-size: $commonFontSize;
  resize: none;
  font-weight: $thinFont;
  border: 1px solid $gray4;
  outline-style: none;
}
.btnWrapper {
  max-width: 101px;
  width: 100%;
  margin: 0 16px;
  padding-bottom: 16px;
}
.white {
  color: $white;
  font-weight: $normalFont;
  font-size: $commonFontSize;
}
.borderBtm {
  border-bottom: 1px solid $gray4;
}
.icon {
  width: 40px;
  height: 40px;
  margin-right: 16px;
  border-radius: 44px;
}
.radius {
  border-radius: 40px;
  max-width: 40px;
  max-height: 40px;
  width: 100%;
  height: 100%;
}
.name {
  font-size: $smallFontSize;
  color: $black;
  width: 100%;
  font-weight: $mediumFont;
}
.profile {
  font-size: $smallFontSize;
  color: $gray2;
}
.starImg {
  margin-right: 20%;
}
.character-count {
  font-weight: $thinFont;
  font-size: $smallFontSize;
  line-height: 120%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: right;
  color: $gray1;
  margin-right: 20px;
}

.error {
  margin-bottom: 0;
  left: 2%;
  position: relative;
}

.proposal_cancel_btn {
  background: transparent;
  font-size: $commonFontSize;
  color: $black;
  font-weight: $normalFont;
}