@import '../../common.scss';

.popup-alert__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.popup-alert__title {
  font-weight: $semiBoldFont;
  margin-top: 15px;
  font-size: $largeFontSize;
  line-height: 150%;
  text-align: center;
  color: $orange2;
}

.popup-alert__content {
  font-style: normal;
  font-weight: normal;
  margin-bottom: 25px;
  font-size: $commonFontSize;
  line-height: 150%;
  text-align: center;
  color: $gray1;
}
