@import '../../common.scss';

.heading {
  font-weight: $semiBoldFont;
  font-size: $headerFontSize;
  margin: 20px 0;
}
.tabWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 1px solid $gray5;
}
.tabElement {
  max-width: 105px;
  width: 100%;
  padding: 21px 0;
  margin-right: 32px;
  border-bottom: 1px solid $blue2;
}
.tabColor {
  color: $black !important;
}
.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .heading {
    font-size: 20px;
  }
}
