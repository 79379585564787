@import '../../common.scss';

.outerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.innerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1158px;
  width: 100%;
}
.eachSide {
  // max-width: 751px;
  width: 100%;
  background-color: $white;
  margin-bottom: 16px;
  max-width: 571px;
}
.wrapper {
  display: flex;
  justify-content: space-between;
}
.label {
  padding: 22px 16px;
  color: $gray2;
  display: flex;
  justify-content: flex-start;
}
.divImg {
  margin-right: 8px;
}
.tab {
  color: $black;
}
.tabsWrapper {
  background-color: $white;
  padding-left: 16px;
  margin-bottom: 60px;
}
.component {
  padding: 16px 16px 16px 0;
}
.btnWrapper {
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;
}
.dropDownIcon {
  background-color: $white5;
  max-width: 180px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 16px;
  font-size: $commonFontSize;
  color: $gray2;
  font-weight: $normalFont;
  margin-right: 10px;
}
.white {
  color: $white;
  max-width: 126px;
  width: 100%;
  font-weight: $normalFont;
  font-size: $commonFontSize;
}

.whitePopup {
  color: $white;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}

.gray {
  color: $white;
  background: $gray7;
  max-width: 126px;
  margin-top: 20px;
  // background-color: $gray1;
  width: 100%;
  font-weight: $normalFont;
  font-size: $commonFontSize;
}
.margins {
  margin: 16px 16px 16px 0;
}
.amountAvailable {
  color: $blue;
  font-size: $largeFontSize;
  font-weight: $semiBoldFont;
}
.aboutFund {
  color: $gray1;
  font-size: $smallFontSize;
}
.fundDetails {
  padding: 16px;
  padding-top: 0 !important;
  display: flex;
  justify-content: space-between;
}
.amountPending {
  font-size: $largeFontSize;
  font-weight: $semiBoldFont;
}
.element {
  padding: 8px;
  font-size: $commonFontSize;
  font-weight: $normalFont;
  color: $gray2;
}
.dropDownWrapper {
  width: 100%;
  display: flex;
}

.dropdown_container {
  margin-right: 16px;
}

.btn {
  max-width: 93px;
  width: 100%;
  background-color: $blue;
  color: $white;
  font-weight: $normalFont;
  font-size: $commonFontSize;
}

.payments {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $gray3;
  padding: 16px 0;
}

.cardNumber {
  font-size: $largeFontSize;
  font-weight: $semiBoldFont;
  max-width: 255px;
  width: 100%;
}

.deleteDiv {
  background-color: $white5;
  max-width: 40px;
  width: 100%;
  padding: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.transactionWrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $gray3;
  padding: 16px 0;
}

.borderTop {
  border-top: 1px solid $gray3;
}

.date {
  font-size: 12px;
  font-weight: 500;
}

.cost {
  font-size: 20px;
  font-weight: 600;
}

.column {
  width: 250px;
}

.course {
  font-size: 16px;
  font-weight: 600;
}

.userName {
  font-size: 14px;
  font-weight: 400;
  color: #96999c;
}

.id {
  color: $blue;
  font-weight: 500;
  font-size: 12px;
}

.key {
  color: #7c7c7c;
  font-size: 14px;
}

.icon {
  max-width: 40px;
  width: 100%;
  max-height: 40px;
  height: 100%;
  border-radius: 16px;
  margin-right: 8px;
}

.rowWrapper {
  display: flex;
  justify-content: flex-start;
}

.d-flex {
  display: flex;
  width: 50%;
}

.bankAccounts {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $gray3;
  padding: 16px 0;
  height: 60px;
}

.radio_label {
  color: $gray2;
  min-height: 57px;
  padding-bottom: 16px;
}

.availableClass {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stripeIcon {
  display: flex;
  font-size: 14px;
  color: $gray1;
  img {
    cursor: unset !important;
    // height: 15px;
    // width: 15px;
    width: 160px;
    height: 50px;
    // margin: 3px 10px 0 3px;
  }
}
