@import '../../common.scss';

.element {
  color: $gray1;
  font-size: $commonFontSize;
  display: flex;
  min-width: 96px;
  width: 100%;
  align-items: center;
}

.section {
  max-width: 1158px;
  width: 100%;
  margin-bottom: 16px;
  background: $white;
  &:last-child {
    margin-bottom: 0px;
  }
}

.search_section {
  padding: 24px 16px 0px 16px;
  .search_heading {
    color: $orange2;
    font-size: $largeFontSize;
    font-weight: $semiBoldFont;
    padding: 0 16px;
    margin-bottom: 24px;
  }
  .request_count {
    color: $blue2;
  }
}

.option_count {
  color: $orange2;
  margin-left: 10px;
}

.list-card-footer {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d-flex {
  display: flex;
}

.dropdown_container {
  height: 48px;
  &:first-child {
    margin-right: 16px;
  }
}

.list-filter {
  display: flex;
  align-items: center;
  padding: 8px 6px;
  max-width: 127px;
  width: 100%;
  font-size: $commonFontSize;
  color: $gray2;
  background: $white5;
  cursor: pointer;
  height: 48px;
  img {
    margin-right: 10px;
  }
}

.list-dropdown {
  display: flex;
  align-items: center;
  padding: 8px 6px;
  min-width: 160px;
  width: 100%;
  font-size: $commonFontSize;
  color: $gray2;
  background: $white5;
  cursor: pointer;
  height: 48px;
  img {
    margin-right: 10px;
  }
}

.contain {
  display: flex;
  justify-content: flex-start;
}

.loader_wrapper {
  padding: 16px;
}

.request_container {
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.request_container_after {
  &::after {
    content: '';
    flex: auto;
    max-width: 365px;
  }
}

.request_card {
  max-width: 365px;
  width: 100%;
  margin-bottom: 16px;
  cursor: pointer;
  & > div {
    margin: 0 !important;
  }
}

.fullWidth {
  max-width: 100%;
}
.pagination_wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.tabHeaders {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  background-color: $blue3;
  color: $white;
  top: 0px;
  z-index: 999;
  border-bottom: 1px solid $gray7;
  border-top: 1px solid $gray7;
}

.tab_container {
  max-width: 1084px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
