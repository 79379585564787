@import '../../common.scss';

.loaderBody {
  max-height: 530px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  padding: 5px 0;
  border: 5px solid $gray3;
  border-radius: 50%;
  border-top: 5px solid $gray1;
  border-left: 5px solid $gray1;
  width: 70px;
  height: 70px;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
