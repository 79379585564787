@import '../../common.scss';

.dialog-header__options {
  display: flex;

  align-items: center;
  width: 100%;
  color: $white;
  font-style: normal;
  font-weight: normal;
  font-size: $commonFontSize;
  line-height: 120%;
  height: 48px;

  img {
    cursor: pointer;
  }
}

// .resize {
//   cursor: pointer;
//   margin-left: auto;

//   img {
//     margin: 0 16px;
//   }
// }
.title {
  text-align: center;

  font-weight: $semiBoldFont;
  font-size: $xlargeFontSize;
  line-height: 150%;
  color: $white;
}
