@import '../../common.scss';

.name {
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
}

.contentWrapper {
  display: flex;
  // justify-content: center;
  max-width: 1158px;
  width: 100%;
  margin-top: 16px;
}
.left {
  width: 60%;
  display: flex;
  flex-direction: column;
}
.right {
  width: 40%;
  display: flex;
  flex-direction: column;
}
.about {
  padding: 16px;
  background-color: $white;
  margin-bottom: 16px;
}

.hourRate {
  margin-bottom: 16px;
  margin-left: 16px;
  background-color: $white;
  padding: 16px;
}
.divImg {
  margin-right: 16px;
}
.heading {
  font-weight: $mediumFont;
  font-size: $mediumFontSize;
  color: $black;
}
.aboutContent {
  color: $gray1;
  font-size: $commonFontSize;
  margin-top: 22px;
}
.margin {
  margin-bottom: 10px;
}
.headingWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
  // margin-top: 6px;
}
.subHeadingWrapper {
  margin-top: 11px;
  display: flex;
  justify-content: space-between;
  padding: 0 16px 16px 16px;
  border-bottom: 1px solid $gray3;
}
.costPerHour {
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
  color: $blue2;
}
.labelInner {
  color: $gray2;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  display: flex;
  justify-content: left;
  padding-top: 10px;
}
.inner {
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  padding-top: 10px;
}
.LanguagesKnown {
  margin-bottom: 16px;
  margin-left: 16px;
  background-color: $white;
}

.autocompletePosition {
  position: relative;
  margin-bottom: 16px;
}

.pointer {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.languagePointer {
  cursor: pointer;
  align-items: center;
  padding-right: 16px;
}

.background {
  background-color: $gray3;
  height: 100%;
  width: 100%;
  padding-top: 16px;
  // padding-bottom: 60px;
}
.center {
  display: flex;
  justify-content: center;
}
.wrapper {
  display: flex;
  justify-content: flex-start;
  max-width: 1158px;
  width: 100%;
  margin-top: 16px;
  background-color: $white;
}
.userImg {
  width: 126px;
  height: 126px;
  border-radius: 50%;
}
.editImg {
  max-height: 48px;
  max-width: 48px;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 60%;
  left: 66%;
  cursor: pointer;
}
.imgWrapper {
  max-width: 130px;
  width: 100%;
  margin: 16px 32px 16px 16px;
  position: relative;
}
.userInfoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding-bottom: 27px;
}
.name {
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
}
.aboutUser {
  font-size: $commonFontSize;
  color: $gray2;
}
.tabsWrapper {
  max-width: 1158px;
  width: 100%;
  max-height: 68px;
  height: 100%;
  background-color: $white;
  padding-left: 16px;
}
.tab {
  color: $black;
}
.label {
  color: $gray2;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  display: flex;
  justify-content: left;
  align-items: center;
}

.skillWidth {
  max-width: 80px;
  width: 100%;
}
.skillsWrapper {
  display: flex;
  justify-content: flex-start;
}
.skillSet {
  background-color: $blue;
  color: $white;
  margin: 8px 8px 16px 0;
  padding: 10px 16px;
  align-items: center;
  font-size: $commonFontSize;
  display: flex;
  justify-content: center;
}
.labelStudent {
  color: $gray2;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  display: flex;
  justify-content: left;
  padding: 25px 16px;
}
.wrapperStudent {
  max-width: 1158px;
  width: 100%;
  margin-top: 16px;
  background-color: $white;
  margin-bottom: 60px;
}
.feedbackCol {
  display: flex;
  justify-content: space-between;
  margin-right: 32px;
  background-color: $white;
  border-bottom: 1px solid $gray7;
}
.information {
  display: flex;
  justify-content: center;
  margin: 20px 16px;
}
.imgFeedback {
  margin-right: 16px;
}
.headingFeedback {
  font-weight: $mediumFont;
  font-size: $mediumFontSize;
  color: $black;
}
.feedbackContent {
  margin: 20px 16px;
}
.feedback {
  margin: 20px 0;
}
.aboutContentFeedback {
  color: $gray1;
  font-size: $commonFontSize;
  max-width: 490px;
  width: 100%;
}
.border {
  margin-right: 32px;
  border-bottom: 1px solid $gray7;
}
.imgWrapperIcon {
  background-color: $green1;
  justify-items: center;
  border-radius: 50%;
  height: 130px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.align {
  display: flex;
  justify-content: center;

  font-weight: $semiBoldFont;
  color: $white;
  font-size: $headerFontSize;
}
.tabItemWrapper {
  margin-top: 16px;
  margin-bottom: 60px;
}
.tabContent {
  margin-top: 16px;
  margin-bottom: 60px;
}
.tabContentWrapper {
  width: 100%;
  height: 600px;
}

.labelSkills {
  color: $gray2;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  display: flex;
  justify-content: left;
  align-items: center;
  // margin-bottom: 8px;
}

.list-card {
  margin-bottom: 16px;
  border: 1px solid $white4;
  padding: 16px;
  display: flex;
  background: $white;
  flex-direction: column;
}

// .label {
//   color: $gray2;
//   font-weight: $mediumFont;
//   font-size: $commonFontSize;
//   display: flex;
//   justify-content: left;
//   // padding: 22px 0;
//   // margin-bottom: 8px;
// }

.heading2 {
  font-weight: $mediumFont;
  font-size: $commonFontSize;
}

.subHeading {
  font-size: $smallFontSize;
  color: $gray2;
  margin-bottom: 8px;
}

.multiSelectWrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  // margin-top: 0px;
  & > div {
    margin-top: 0;
    margin-bottom: 16px;
  }
}
.multiSelectWrapperSkills {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
}
.costContent {
  color: $gray1;
  font-size: $smallFontSize;
}
.button {
  margin-top: 16px;
  display: flex;
  max-width: 1153px;
  width: 100%;
  justify-content: flex-start;
  background-color: $white;
}

.buttonClass {
  max-width: 101px;
  width: 100%;
  max-height: 48px;
  height: 100%;
  color: $white;
  padding: 15.5px 16px;
  font-size: $commonFontSize;
}

.textareaStyles {
  width: 100%;
  padding: 15px;
  line-height: 19px;
  height: 148px;
  font-size: $commonFontSize;
  resize: none;
  font-weight: $thinFont;
  border: 1px solid $gray4;
  outline-style: none;
}

.smallField {
  max-width: 45px;
}

.wrapperLeft {
  display: flex;
  justify-content: left;
  padding-left: 16px;
}

.fullField {
  width: 100%;
  margin-bottom: 16px;
}

.dolar {
  font-weight: $boldFont;
}

.halfField {
  max-width: 248px;
  width: 100%;
  margin-bottom: 16px;
}

.cost {
  border-bottom: 1px solid $gray3;
}

.borderLine {
  display: flex;
  justify-content: space-between;
  padding-right: 151px;
  border-bottom: 1px solid $gray3;
  padding-top: 16px;
}

.color {
  color: $blue2;
}

.error-msg {
  font-size: $smallFontSize;
  color: $red;
  font-weight: $thinFont;
  position: absolute;
}

.pointerPlus {
  cursor: pointer;
  padding-top: 10px;
}

.marginTop {
  margin-top: 22px;
}

.text_field {
  font-size: $commonFontSize;
}
.LanguageHeadingWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
  margin-top: 16px;
  padding-left: 16px;
}
.educationWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.innerEducation {
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  padding-top: 10px;
  max-width: 270px;
  width: 100%;
}
.aboutContentEducation {
  color: $gray1;
  font-size: $commonFontSize;
  max-width: 270px;
  width: 100%;
}
.headingWrapperPopup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 8px;
}
.subHeadingCost {
  font-size: $smallFontSize;
  color: $gray2;
  margin-bottom: 16px;
  padding-left: 16px;
}
.subHeadingRate {
  font-size: $smallFontSize;
  color: $gray2;
  padding-left: 16px;
}
.list-cardCost {
  margin-bottom: 16px;
  border: 1px solid $white4;
  display: flex;
  background: $white;
  flex-direction: column;
  padding: 16px 0;
}
.headingWrapperPopupCost {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 8px;
  padding-left: 16px;
}
.headingCost {
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  padding-left: 16px;
}
.buttonLeft {
  padding: 16px 16px 0 16px;
  display: flex;
  max-width: 1153px;
  width: 100%;
  justify-content: flex-start;
  background-color: $white;
}

.noBottomPadding {
  padding-bottom: 0;
}

.text_area_help_text {
  display: flex;
  justify-content: flex-end;
  position: relative;
  font-size: $smallFontSize;
  font-weight: $thinFont;
}
