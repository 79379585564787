@import '../../common.scss';

.sessionCard {
  width: 365px;
  height: 212px;
  margin-bottom: 20px;
  margin-right: 10px;
  display: flex;
  font-style: normal;
  border: 1px solid #f0f1f3;
  flex-direction: column;
  cursor: pointer;
}
.sessionCard:nth-child(odd) {
  .sessionCardImg {
    background: #4b4bbc;
  }
}
.sessionCard:nth-child(even) {
  .sessionCardImg {
    background: #e54dc0;
  }
}
.sessionCard:nth-child(6n + 1) {
  .sessionCardImg {
    background-image: url(../../assets/images/png/bg-1.png);
    background-size: cover;
  }
}
.sessionCard:nth-child(6n + 2) {
  .sessionCardImg {
    background-image: url(../../assets/images/png/bg-2.png);
    background-size: cover;
  }
}
.sessionCard:nth-child(6n + 3) {
  .sessionCardImg {
    background-image: url(../../assets/images/png/bg-3.png);
    background-size: cover;
  }
}
.sessionCard:nth-child(6n + 4) {
  .sessionCardImg {
    background-image: url(../../assets/images/png/bg-4.png);
    background-size: cover;
  }
}
.sessionCard:nth-child(6n + 5) {
  .sessionCardImg {
    background-image: url(../../assets/images/png/bg-5.png);
    background-size: cover;
  }
}
.sessionCard:nth-child(6n) {
  .sessionCardImg {
    background-image: url(../../assets/images/png/bg-6.png);
    background-size: cover;
  }
}

.courseIcon {
  width: 85px;
  height: 85px;
}

.courseTitle {
  width: 70%;
  text-align: center;
  margin-left: 10px;
}

.sessionCardTimer {
  padding: 16px;
  color: #96999c;
  font-size: 14px;
  border-top: 1px solid #fff4;
  line-height: 160%;
}
.sessionCardImg {
  height: 157px;
  padding: 16px;
  position: relative;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
  line-height: 150%;
  color: #fff;

  & div {
    position: relative;
    z-index: 4;
  }
}
.imgBg {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
}
