@import '../../common.scss';
.label {
  color: $gray2;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  display: flex;
  justify-content: left;
  padding: 22px 0;
  // margin-bottom: 8px;
}
.divImg {
  margin-right: 16px;
}
.heading {
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  margin-bottom: 8px;
}

.formName {
  margin-left: 15px;
}
.wrapper {
  display: flex;
  justify-content: center;
}

.eachSide {
  width: 50%;
  margin-bottom: 16px;
  &:first-child {
    margin-right: 16px;
  }
}
.fullField {
  width: 100%;
  margin-bottom: 35px;
}
.buttons {
  max-width: 121px;
  width: 100%;
  max-height: 48px;
  height: 100%;
  margin: 32px 0;
  color: $white;
  padding: 15.5px 16px;

  font-size: $commonFontSize;
}
.error {
  font-size: $smallFontSize;
  color: $red;
  font-weight: $thinFont;
  position: absolute;
  top: 100%;
}
.errorPassword {
  font-size: $smallFontSize;
  color: $red;
  font-weight: $thinFont;
  position: relative;
  top: -15px;
}
.margin {
  margin-right: 16px;
}
.imageEdit {
  max-width: 48px;
  max-height: 48px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 67%;
  left: 65%;
}
.centerDiv {
  position: relative;
  max-width: 125px;
  max-height: 126px;
  width: 100%;
  height: 100%;
  margin: 20px 0;
}
.outerDiv {
  display: flex;
  justify-content: center;
}
.imageProfile {
  width: 125px;
  height: 125px;
  border-radius: 50%;
}
.marginBtm {
  margin-bottom: 16px;
}

.phone_number_wrapper {
  display: flex;
  & > div {
    &:first-child {
      position: relative;
      margin-right: 11px;
    }
  }
}

.phone_number {
  width: 100%;
}
.cancel-btn {
  background-color: $gray0;
  color: $black;
  margin-top: 10px;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
.green {
  color: $white;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
.white {
  color: $white;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}
.phoneno {
  max-width: 243px;
  width: 100%;
}
.paddingRight {
  padding-right: 36px;
}

.country {
  position: relative;
  width: 100%;
}

@media screen and (max-width: 960px) {
  .eachSide {
    width: 100%;
  }

  .wrapper {
    flex-direction: column;
  }

  .formName {
    margin-left: 0px;
  }

  .phoneno {
    max-width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .eachSide {
    width: 50%;
  }

  .wrapper {
    flex-direction: row;
  }
}

@media screen and (max-width: 600px) {
  .eachSide {
    width: 100%;
  }

  .wrapper {
    flex-direction: column;
  }

  .formName {
    margin-left: 0px;
  }

  .buttons {
    max-width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .btnMrgn {
    margin: 55px 0 32px;
  }
}
