@import "../../common.scss" ;

.accordionHeader{
      padding : 24px 32px;
      height : 72px;
      background : linear-gradient(0deg, $white3, $white3), $white;
    }
.accordionList{
      border : 1px solid $gray3;
      padding : 24px 16px;
      height : 72px;
    }
  