@import '../../common.scss';

.section {
  max-width: 1158px;
  width: 100%;
  margin-bottom: 16px;
  background: $white;
  &:last-child {
    margin-bottom: 0px;
  }
}
.time-subject {
  font-size: $commonFontSize;
}
.proposal_list {
  padding: 16px;
}

.search_section {
  padding-top: 24px;
  max-width: 1158px;
  width: 100%;

  .search_heading {
    color: $orange2;
    font-size: $largeFontSize;
    font-weight: $semiBoldFont;
    padding: 0 16px;
    margin-bottom: 8px;
    margin-left: 16px;
  }
  .request_count {
    color: $blue2;
  }
}

.option_count {
  color: $orange2;
  margin-left: 10px;
}

.list-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 178px;
  width: 100%;
  padding-bottom: 16px;
  margin-left: 32px;
}

.white {
  color: $white;
  font-size: $commonFontSize;
  font-weight: normal;
  img {
    margin-right: 8px;
  }
}

.list-card {
  border: 1px solid $white4;
  padding: 16px;
  display: flex;
  background: $white;
  margin-bottom: 16px;
  flex-direction: column;
}

.list-card:last-child {
  margin-bottom: 0px;
}

.request-respond {
  padding: 5px 0;
  height: 22px;
  color: $gray2;
  font-size: $commonFontSize;
  line-height: 120%;
  font-weight: $mediumFont;
  display: flex;
  img {
    margin-right: 8px;
    width: 12px;
    height: 16px;
  }
}

.d-flex {
  display: flex;
}

.list-card-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.list-card-title {
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  line-height: 160%;
  color: $orange2;
  margin-top: 16px;
}

.list-card-content {
  font-size: $commonFontSize;
  line-height: 160%;
  color: $gray2;
}

.textarea-styles {
  width: 100%;
  margin-top: 8px;
  padding: 15px;
  line-height: 19px;
  height: 100px;
  font-size: $mediumFontSize;
  resize: none;
  border: 1px solid $gray4;
  outline-style: none;
}

.errorPosition {
  position: relative;
}

.character-count {
  font-weight: $thinFont;
  font-size: $smallFontSize;
  line-height: 120%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: right;
  color: $gray1;
}

.error {
  color: $red;
  margin-bottom: 10px;
  font-size: $smallFontSize;
  font-weight: $thinFont;
  position: absolute;
}

.input-wrapper {
  margin-top: 8px;
}

.autocompletePosition {
  margin-top: 8px;
  position: relative;
}

.radio_label {
  border-bottom: 1px solid $gray3;
  color: $gray2;
  height: 57px;
}

.radio_label:last-child {
  border-bottom: none;
}

.radio-wrapper {
  margin-top: 22px;
}

.borderLine {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $gray3;
  padding-top: 16px;
}

.headingCost {
  font-weight: $mediumFont;
  font-size: $commonFontSize;
}

.subHeadingCost {
  font-size: $smallFontSize;
  color: $gray2;
  margin-bottom: 16px;
}

.dolar {
  font-weight: $boldFont;
  width: 20%;
}

.dolorBold {
  font-weight: $boldFont;
}

.color {
  color: $blue2;
}

.buttonClass {
  max-width: 180px;
  width: 100%;
  max-height: 48px;
  height: 100%;
  color: $white;
  font-weight: normal;
  padding: 15.5px 16px;
  font-size: $commonFontSize;
}

.buttonLeft {
  padding: 16px 16px 0 0;
  display: flex;
  max-width: 1153px;
  width: 100%;
  justify-content: flex-start;
  background-color: $white;
}

.divImg {
  margin-right: 16px;
}

.titleContainer {
  width: 80%;
}

.halfField {
  max-width: 280px;
  width: 100%;
  margin-top: 8px;
}

.cost {
  border-bottom: 1px solid $gray3;
  display: flex;
}

.smallField {
  max-width: 42px;
  width: 100%;
  margin-top: 8px;
}

.wrapperLeft {
  display: flex;
  flex-direction: column;
  max-width: 326px;
  width: 100%;
}

.wrapperRight {
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  max-width: 345px;
}

.hourBlock {
  width: 48px;
  height: 48px;
  border: 1px solid $gray4;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $commonFontSize;
  color: $gray2;
  margin-right: 16px;
  margin-top: 8px;
  cursor: pointer;
}

.hourBlock:last-child {
  margin-right: 0px;
}

.selectedBlock {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $commonFontSize;
  color: $white;
  background: $blue2;
  margin-right: 16px;
  margin-top: 8px;
  cursor: pointer;
}

.timeBlock {
  max-width: 147px;
  width: 100%;
  margin-right: 30px;
}

.input-time {
  max-width: 147px;
  font-size: $mediumFontSize;
}

.input-end-time {
  border: none;
  max-width: 147px;
  font-size: $mediumFontSize;
  background: $white5;
  color: $gray2;
}
.marginLeft {
  margin-left: 16px;
}
.pagination {
  display: flex;
  margin-bottom: 24px;
  justify-content: center;
}
.proposalWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 16px;
  width: 100%;
  max-width: 1158px;
}

.mTop {
  margin-top: 0px;
}

.rowWrapper {
  display: flex;
  background-color: $gray3;
  justify-content: center;
  max-width: 1153px;
  width: 100%;
}

.request-lists-right {
  max-width: 381px;
  margin-left: 16px;
  width: 100%;
  background: $white;
  padding: 16px 16px 0;
  height: 520px;
}

.review-card__align-items {
  display: flex;
  align-items: center;
  margin-right: 28px;
  white-space: nowrap;
  margin-bottom: 16px;
  height: 22px;
  font-size: $xsmalFontSize;
  line-height: 120%;
  color: $gray2;
  font-weight: $mediumFont;
}

.review-card__time-icon {
  margin-right: 5px;
}
.chat_section {
  padding-top: 24px;
  width: 741px;
  .search_heading {
    color: $orange2;
    font-size: $largeFontSize;
    font-weight: $semiBoldFont;
    padding: 0 16px;
    margin-bottom: 8px;
    margin-left: 16px;
  }
  .request_count {
    color: $blue2;
  }
}

.marginBtm {
  margin-bottom: 0 !important;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
