@import '../../common.scss';

.footer {
  max-width: 1094px;
  width: 100%;
  padding-bottom: 27px;
}

.footer__logo {
  max-width: 138px;
  width: 100%;
  height: 40px;
  margin: 80px 0 60px;
}

.footer__email {
  margin-bottom: 32px;
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
  line-height: 150%;
  color: $orange2;
}

.footer__section {
  display: flex;
  width: 100%;
  // height: 96px;
  padding-bottom: 55px;
  border-bottom: 1px solid rgba($color: $orange1, $alpha: 0.1);
  justify-content: space-between;
}

.footer__contact-info {
  font-size: $mediumFontSize;
  line-height: 150%;
  color: $gray1;
  max-width: 349px;
  width: 100%;
}
.links-div {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.links {
  margin-top: 10px;
  text-align: right;
}
.footer__social-media {
  margin-top: 27px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .footer__rights {
    width: 50%;
    font-size: $commonFontSize;
    line-height: 150%;
    color: $orange2;
  }
}

.footer__social-media-icon {
  display: flex;
  img {
    width: 57px;
    height: 48px;
    margin-left: 16px;
  }
}
