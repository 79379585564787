@import '../../common.scss';

.help_center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background: $gray5;
  padding: 16px 0 50px;
}
.para {
  margin: 0 20px;
  opacity: 0.6;
  font-size: $mediumFontSize;
}
.accordionSection {
  max-width: 1158px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}
.accordionDetails {
  padding: 16px 14px;
  display: flex;
  flex-direction: column;
}
.accordionHeader {
  padding: 24px 32px;
  height: 72px;
  background: linear-gradient(0deg, $white3, $white3), $white;
}
.accordion {
  background: linear-gradient(0deg, $white3, $white3), $white;
  box-shadow: none !important;
  margin-bottom: 16px;
}

.accordionHeaderSection {
  font-size: $largeFontSize;
  color: $orange2;
  align-items: center;
  font-style: normal;
  display: flex;
  font-weight: $semiBoldFont;
  line-height: 150%;

  & span {
    padding: 0px 10px 0 0;
  }
}

.tab_container {
  background-color: $blue3;
  width: 100%;
}

.footer_wrapper {
  display: flex;
  justify-content: center;
  max-width: 1094px;
  width: 100%;
}
