@import '../../common.scss';
.wrapper {
  display: flex;
  justify-content: center;
}
.tabHeaders {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  background-color: $blue3;
  color: $white;
  top: 0px;
  z-index: 999;
  border-bottom: 1px solid $gray7;
  border-top: 1px solid $gray7;
}
.container {
  max-width: 1084px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.tabContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 0 60px 0;
  opacity: 0.98;
  background: linear-gradient(0deg, $gray0, $gray0), $white;
}
