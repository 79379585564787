@import '../../common.scss';

.reviews {
  width: 100%;
  display: flex;
  align-items: center;
  background: $blue2;
  flex-direction: column;
}
.cardWidth {
  max-width: 365px;
  width: 100%;
}
.reviewHeight {
  height: 530px;
}
.signUpHeight {
  height: 100vh;
}
.reviewsHeader {
  font-weight: $semiBoldFont;
  width: 100%;
  margin: 80px 0 40px;
  font-size: $headerFontSize;
  line-height: 150%;
  color: $green1;
}
.reviewHeaderWidth {
  max-width: 1094px;
}
.signUpHeaderWidth {
  max-width: 556px;
}
.signUpHead {
  font-size: $mediumFontSize;
  color: $white;
  max-width: 554px;
  width: 100%;
  margin: 40px 0 40px;
}
.slideSwiper {
  max-width: 365px;
  width: 100% !important;
}

@media screen and (max-width: 1500px) {
  .reviewsHeader {
    margin: 40px 0 0;
    font-size: 36px;
  }

  .signUpHead {
    margin: 20px 0;
  }
}

@media screen and (max-width: 1170px) {
  .reviewsHeader {
    margin: 20px 0 0;
    padding: 0 20px;
    font-size: 30px;
  }

  .signUpHead {
    margin: 20px 0;
    padding: 0 20px;
  }
}

@media screen and (max-width: 850px) {
  .reviewsHeader {
    font-size: 20px;
  }
}
