@import '../../common.scss';

.search {
  width: 100%;
  max-width: 1158px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: linear-gradient(0deg, $white, $white), $white3;
}

.search-title {
  font-style: normal;
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
  line-height: 150%;
  margin: 8px 0 24px 16px;
  color: $orange2;
}

.input-search {
  font-weight: $normalFont;
  font-size: $commonFontSize;
  line-height: 19px;
  background: $white;
  color: $gray2;
  background-image: url(../../assets/images/svg/search-icon.svg);
  background-repeat: no-repeat;
  background-position: 98%;
  width: 100%;
  border: 1px solid $gray4;
  outline: none;
  padding: 14px 25px 14px 16px;
  cursor: pointer;
}
