@import '../../common.scss';
.section {
  max-width: 1158px;
  width: 100%;
  margin-bottom: 16px;
  background: $white;
  &:last-child {
    margin-bottom: 0px;
  }
}

.search_section {
  padding: 24px 16px 16px 16px;

  .search_heading {
    color: $orange2;
    font-size: $largeFontSize;
    font-weight: $semiBoldFont;
    padding: 0 16px;
    margin-bottom: 24px;
  }
  .request_count {
    color: $blue2;
  }
}

.register_section {
  max-width: 364px;
  width: 100%;
  margin: 60px auto;
  .teacher_image {
    max-width: 274px;
    max-height: 140px;
    width: 100%;
    height: 100%;
    margin-bottom: 25px;
  }
  .register_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 24px;
    .register_heading {
      font-size: $largeFontSize;
      font-weight: $semiBoldFont;
      text-align: center;
      max-width: 273px;
      margin-bottom: 16px;
      color: $orange2;
    }
    .register_text {
      font-size: $commonFontSize;
      font-weight: $normalFont;
      color: $gray1;
    }
  }
}
.register_button {
  background: $blue2;
  color: $white;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}

.option_count {
  color: $orange2;
  margin-left: 10px;
}

.request_card {
  max-width: 365px;
  width: 100%;
  margin-bottom: 16px;
  cursor: pointer;
  & > div {
    margin: 0 !important;
  }
}

.request_container {
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.request_container_after {
  &::after {
    content: '';
    flex: auto;
    max-width: 365px;
  }
}

.pagination_wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.loader_wrapper {
  padding: 16px;
}

.cancel-btn {
  background-color: $gray0;
  color: $black;
  margin-top: 10px;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}

.imgWidth {
  max-width: 274px;
  width: 100%;
}
