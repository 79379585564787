@import '../../common.scss';

.wrapper {
  padding: 16px;
  background-color: $white;
}

.element {
  margin-bottom: 16px;
}

.bold {
  font-weight: $mediumFont;
  font-size: $commonFontSize;
}

.description {
  font-weight: $normalFont;
  font-size: $smallFontSize;
  margin-bottom: 8px;
  color: $gray1;
}

.white {
  color: $white;
  font-weight: $normalFont;
  font-size: $commonFontSize;
}

.green {
  color: $white;
  font-weight: $normalFont;
  font-size: $commonFontSize;
}

.cancel {
  background-color: $gray0;
  color: $black;
  font-size: $commonFontSize;
  margin-top: 10px;
  font-weight: normal;
}

.teamDets {
  margin-top: 3px;
  margin-bottom: 34px;
}
.textarea-styles {
  width: 100%;
  margin-top: 8px;
  padding: 15px;
  line-height: 19px;
  height: 100px;
  font-size: $mediumFontSize;
  resize: none;
  border: 1px solid $gray4;
  outline-style: none;
}

.character-count {
  font-weight: $thinFont;
  font-size: $smallFontSize;
  line-height: 120%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: right;
  color: $gray1;
}

.divImg {
  margin-right: 8px;
}

.button {
  font-weight: $normalFont;
  font-size: $commonFontSize;
  max-width: 112px;
  width: 100%;
  color: $white;
}
.largeWidth {
  max-width: 186px;
  width: 100%;
}

.align {
  display: flex;
  justify-content: center;
  position: relative;
}

.edit {
  position: absolute;
  bottom: 0%;
  right: 40%;
  cursor: pointer;
}

.teamPic {
  max-width: 128px;
  height: 126px;
  width: 100%;
  border-radius: 50%;
}

.error {
  color: $red;
  font-size: $smallFontSize;
  font-weight: $normalFont;
}
.relative {
  position: relative;
}
