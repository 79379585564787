@import '../../common.scss';

.section {
  max-width: 761px;
  width: 100%;
  margin-bottom: 16px;
  background: $white;
  &:last-child {
    margin-bottom: 0px;
  }
}

.search_section {
  padding: 24px 16px 16px;
  width: 100%;

  .search_heading {
    color: $orange2;
    font-size: $largeFontSize;
    font-weight: $semiBoldFont;
    padding: 0 16px;
    margin-bottom: 8px;
  }
}

.invite_btn {
  width: 143px;
  font-size: $commonFontSize !important;
  font-weight: $normalFont !important;
  color: $white !important;
  margin: 30px 0 0;
}

.user_img {
  margin-right: 16px;
}

.memberTop {
  margin-top: 16px;
}

.members {
  max-width: 729px;
  width: 100%;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $gray5;
}

.membersList-userDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 32px;
  margin-left: 16px;
}

.membersList-username {
  font-weight: $mediumFont;
  font-size: $smallFontSize;
  line-height: 128%;
  color: $orange2;
}

.membersList-role {
  font-weight: $normalFont;
  font-size: $smallFontSize;
  line-height: 128%;
  color: $orange2;
}

.membersList-profile {
  width: 100%;
  max-width: 180px;
  height: 40px;
  display: flex;
  align-items: center;
}

.userImg {
  height: 40px;
  width: 40px;
  justify-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.delete_img {
  margin-left: 16px;
  cursor: pointer;
}

.red {
  color: $white;
  background: $red1;
  font-size: $commonFontSize;
  font-weight: normal;
}

.cancel-btn {
  background-color: $gray0;
  color: $black;
  font-size: $commonFontSize;
  margin-top: 10px;
  font-weight: normal;
}

.landing-page__search {
  width: 100%;
  cursor: pointer;
  position: relative;
}

#searchLearn {
  font-size: $commonFontSize;
  border: 1px solid $gray4;
  width: 100%;
  max-width: 729px;
  outline: none;
  padding: 16px;

  &::placeholder {
    opacity: 0.4;
  }
}

.list {
  margin: 0;
  padding: 0;
  max-height: 147px;
  position: absolute;
  background: $white;
  overflow: auto;
  width: 100%;

  li {
    height: 48px;
    font-size: $commonFontSize;
    line-height: 120%;
    color: $gray2;
    display: flex;
    margin: 0 16px;
    align-items: center;
    list-style-type: none;
    border-bottom: 1px solid $gray3;
    cursor: pointer;

    div {
      margin-left: 10px;
    }
  }
}

.mTop {
  margin-top: 140px;
}

.marginBottom {
  margin-right: 12px;
}

.rating {
  display: flex;
  justify-content: center;
}

.star {
  margin-right: 12px;
}

.feedbackContent {
  margin: 5px 0;
}
