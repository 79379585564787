@import '../../common.scss';

.input-wrapper {
  width: inherit;
  position: relative;
}

.common-input-style {
  background: $white;
  border: 1px solid $gray4;
  width: 100%;
  height: 48px;
  outline: none;
  padding: 0 15px;
  // padding-right: 36px;
}

.input-icon {
  position: absolute;
  top: 18px;
  right: 15px;
}

.error-msg {
  font-size: $smallFontSize;
  color: $red;
  font-weight: $thinFont;
  position: absolute;
  top: 100%;
}
