@import '../../common.scss';

.section {
  margin-left: 16px;
  padding: 16px;
  background-color: $white;
}

.title {
  display: flex;
  justify-content: flex-start;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  color: $gray2;
  margin-bottom: 25px;
}

.divImg {
  margin-right: 12px;
}

.button {
  background-color: $blue;
  font-size: $commonFontSize;
  font-weight: $mediumFont;
  color: $white;
}
