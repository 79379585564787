@import '../../common.scss';

.background {
  background-color: $gray3;
  height: 100%;
  width: 100%;
  padding-top: 16px;
  // padding-bottom: 60px;
}
.center {
  display: flex;
  justify-content: center;
}
.wrapper {
  display: flex;
  justify-content: flex-start;
  max-width: 1158px;
  width: 100%;
  margin-top: 16px;
  background-color: $white;
}
.userImg {
  width: 126px;
  height: 126px;
  border-radius: 50%;
}
.editImg {
  max-height: 48px;
  max-width: 48px;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 60%;
  left: 66%;
  cursor: pointer;
}
.imgWrapper {
  max-width: 130px;
  width: 100%;
  margin: 16px 32px 16px 16px;
  position: relative;
}
.userInfoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.name {
  font-weight: $semiBoldFont;
  font-size: $largeFontSize;
}
.tabsWrapper {
  max-width: 1158px;
  width: 100%;
  max-height: 68px;
  height: 100%;
  background-color: $white;
  padding-left: 16px;
}
.tab {
  color: $black;
}
.pointer {
  cursor: pointer;
  display: flex;
}
.imgWrapperIcon {
  background-color: $green1;
  justify-items: center;
  border-radius: 50%;
  height: 130px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.align {
  display: flex;
  justify-content: center;

  font-weight: $semiBoldFont;
  color: $white;
  font-size: $headerFontSize;
}

.tabContent {
  margin-top: 16px;
}
.tabContentWrapper {
  width: 100%;
}

.list-card {
  margin-bottom: 16px;
  border: 1px solid $white4;
  padding: 0 16px;
  display: flex;
  background: $white;
  flex-direction: column;
}

.loader {
  position: absolute;
  top: 60%;
  left: 66%;
  max-height: 48px;
  max-width: 48px;
  height: 100%;
  width: 100%;
  background-color: $blue;
  border-radius: 50%;
  align-items: center;
  padding-left: 10px;
}
