@import '../../common.scss';

.bankDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: $white;
  padding: 5px;
}

.form-row {
  margin: 0 16px;
  max-width: 715px;
}

.submit {
  width: 200px;
  max-height: 48px;
  height: 100%;
  margin: 16px;
  color: $white;
  padding: 15.5px 16px;
  font-size: $commonFontSize;
}

.label {
  margin: 16px 0 8px;
}

.white {
  color: $white;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}

.labelCost {
  color: $gray2;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
  display: flex;
  justify-content: left;
  padding: 22px 0 0;
  margin-left: 16px;
}

.divImg {
  margin-right: 16px;
}

.holderType {
  position: relative;
  margin-bottom: 20px;
}

.error {
  top: 100%;
}
