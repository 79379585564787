@import '../../common.scss';

.whiteBackground {
  background-color: $white;
  padding: 15px;
}
.heading {
  display: flex;
  justify-content: flex-start;
  padding: 24px 16px;
  color: $gray2;
  font-weight: $mediumFont;
  font-size: $commonFontSize;
}
.divImg {
  margin-right: 16px;
}
.divField {
  margin-right: 8px;
}
.boldText {
  font-size: $commonFontSize;
  font-weight: $semiBoldFont;
}

.padding {
  padding: 0 16px;
}

.inputField {
  margin-top: 8px;
  padding-bottom: 16px;
}

.cardDetails {
  display: flex;
  justify-content: flex-start;
}

.cardNumber {
  max-width: 320px;
  width: 100%;
  margin-right: 76px;
  padding-bottom: 16px;
}

.fieldForNumber {
  display: flex;
  justify-content: space-evenly;
  margin-top: 8px;
}

.inputFieldNumber {
  max-width: 68px;
  width: 100%;
}

.column2 {
  display: flex;
  justify-content: space-evenly;
  max-width: 320px;
  width: 100%;
}

.commonBtn {
  max-width: 93px;
  width: 100%;
  padding: 16px 0;
}
.btn {
  color: $white;
  font-weight: 400;
  font-size: 14px;
}

.white {
  color: $white;
  font-size: $commonFontSize;
  font-weight: $normalFont;
}

.cancel-btn {
  color: $gray2;
  background-color: $white5;
  font-weight: $normalFont;
  font-size: $commonFontSize;
}

.bankDetailsInput {
  padding-left: 0px;
  text-align: center;
}

.button {
  color: $white;
  max-width: 100px;
  width: 100%;
  font-size: 16px;
  margin-right: 16px;
  margin-top: 16px;
}

.dflex {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 15px;
}
