@import '../../common.scss';

.icon {
  width: 40px;
  height: 40px;
  border-radius: 44px;
}

.img {
  max-width: 48px;
  max-height: 48px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;
}
